"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FacebookPixelStep = exports.FB_PIXEL_EVENT_TYPE = void 0;
// event types are standardized: https://www.facebook.com/business/help/402791146561655?id=1205376682832142
// this is how we use them:
var FB_PIXEL_EVENT_TYPE;
(function (FB_PIXEL_EVENT_TYPE) {
    FB_PIXEL_EVENT_TYPE["Lead"] = "Lead"; /* applicant/advisor user inputs phone number */
    FB_PIXEL_EVENT_TYPE["SubmitApplication"] = "SubmitApplication"; /* applicant/advisor user receives a prequal offer - also used for PQ for rewards only */
    FB_PIXEL_EVENT_TYPE["StartTrial"] = "StartTrial"; /* used for when LA within AvenCash policy is prequalified */
    FB_PIXEL_EVENT_TYPE["CompleteRegistration"] = "CompleteRegistration"; /* applicant clicks continue on prequal offer */
    FB_PIXEL_EVENT_TYPE["AddToCart"] = "AddToCart"; /* applicant views an offer */
    FB_PIXEL_EVENT_TYPE["Purchase"] = "Purchase"; /* applicant schedules or signs now */
    FB_PIXEL_EVENT_TYPE["Subscribe"] = "Subscribe"; /* all applicants have successfully completed notarization  */
    // advisor only events
    FB_PIXEL_EVENT_TYPE["AddPaymentInfo"] = "AddPaymentInfo"; /* advisor notification permission granted */
    FB_PIXEL_EVENT_TYPE["InitiateCheckout"] = "InitiateCheckout"; /* advisor submits stated goal */
})(FB_PIXEL_EVENT_TYPE = exports.FB_PIXEL_EVENT_TYPE || (exports.FB_PIXEL_EVENT_TYPE = {}));
var FacebookPixelStep;
(function (FacebookPixelStep) {
    FacebookPixelStep["Lead"] = "Lead";
    FacebookPixelStep["PreQualification"] = "PreQualification";
    FacebookPixelStep["PreQualificationContinue"] = "PreQualificationContinue";
    FacebookPixelStep["ViewOffer"] = "ViewOffer";
    FacebookPixelStep["Schedule"] = "Schedule";
    FacebookPixelStep["SuccessNotarization"] = "SuccessNotarization";
    // Advisor events
    FacebookPixelStep["PhoneNumberSubmit"] = "PhoneNumberSubmit";
    FacebookPixelStep["OTPSuccess"] = "OTPSuccess";
    FacebookPixelStep["SSNSuccess"] = "SSNSuccess";
    FacebookPixelStep["PIISuccess"] = "PIISuccess";
    FacebookPixelStep["KBASuccess"] = "KBASuccess";
    FacebookPixelStep["NotificationPermissionGranted"] = "NotificationPermissionGranted";
    FacebookPixelStep["StatedGoalSubmit"] = "StatedGoalSubmit";
})(FacebookPixelStep = exports.FacebookPixelStep || (exports.FacebookPixelStep = {}));
