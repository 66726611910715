import { RouteConfig } from 'vue-router'

const routeName = 'support'

const supportPageNames = {
    SUPPORT_HOME: 'support_home',
    SUPPORT_ARTICLE: 'support_article',
    SUPPORT_CALL_BOOKING: 'support_call_booking',
    SUPPORT_CALL_BOOKING_GENERAL_OG: 'support_call_booking_general_og',
    SUPPORT_TICKET_SUBMIT_FEEDBACK: 'support_submit_feedback',
}

const supportPagePaths = {
    SUPPORT_HOME: `/${routeName}`,
    SUPPORT_ARTICLE: `/${routeName}/article/:id`,
    SUPPORT_CALL_BOOKING: `/${routeName}/call_booking/:bookingUrl?`,
    // Only support accept as last param
    SUPPORT_TICKET_SUBMIT_FEEDBACK: `/${routeName}/feedback/:ticketId/:predictionId/:returnToken/:decision(accept)`,
    SUPPORT_CALL_BOOKING_GENERAL_OG_WITH_OPTIONAL_CALENDAR_KEY: `/call/:calendarKey?`,
    SUPPORT_CALL_BOOKING_GENERAL_OG: `/call`, // do not register, just use it on router commands without a calendarKey
}

export const supportPageRoutes: RouteConfig[] = [
    {
        path: supportPagePaths.SUPPORT_HOME,
        name: supportPageNames.SUPPORT_HOME,
        component: () => import(/* webpackChunkName: "support" */ '@/pages/support/SupportLanding.vue'),
        meta: { public: true },
    },
    {
        path: supportPagePaths.SUPPORT_CALL_BOOKING,
        name: supportPageNames.SUPPORT_CALL_BOOKING,
        component: () => import(/* webpackChunkName: "support" */ '@/pages/support/SupportCallBooking.vue'),
        meta: { public: true },
    },
    {
        path: supportPagePaths.SUPPORT_CALL_BOOKING_GENERAL_OG_WITH_OPTIONAL_CALENDAR_KEY,
        name: supportPageNames.SUPPORT_CALL_BOOKING_GENERAL_OG,
        component: () => import(/* webpackChunkName: "support" */ '@/pages/support/SupportCallBooking.vue'),
        meta: { public: true },
    },
    {
        path: supportPagePaths.SUPPORT_ARTICLE,
        name: supportPageNames.SUPPORT_ARTICLE,
        component: () => import(/* webpackChunkName: "support" */ '@/pages/support/SupportArticle.vue'),
        meta: { public: true },
    },
    {
        path: supportPagePaths.SUPPORT_TICKET_SUBMIT_FEEDBACK,
        name: supportPageNames.SUPPORT_TICKET_SUBMIT_FEEDBACK,
        component: () => import(/* webpackChunkName: "support" */ '@/pages/support/SupportTicketFeedback.vue'),
        meta: { public: true },
    },
]

export { supportPagePaths, supportPageNames }
