import { RouteConfig } from 'vue-router'
import { marketingPageNames, marketingPagePaths } from '@/routes/marketingRoutes'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { AvenRouteConfig } from '@/routes/router'
import { PifRewardType } from '@/routes/pifForAllRoutes'

export const defaultPagePaths = {
    DEFAULT: '/',
    DEFAULT_JOIN: '',
}

// TODO: clean up inactive/unused paths
export const experimentPageNames = {
    // base names
    JOIN_AVEN: 'joinaven',
    AVEN_HELOC: 'avenheloc',
    AVEN_CASH: 'avencash',
    AVEN_CASH_V2: 'avencashv2',
    AVEN_CREDIT: 'avencredit',
    GO_AVEN: 'goaven',
    TRY_AVEN: 'tryaven',
    MEET_AVEN: 'meetaven',
    VISIT_AVEN: 'visitaven',
    FIND_AVEN: 'findaven',
    DISCOVER_AVEN: 'discoveraven',
    SALUTATIONS_AVEN: 'salutationsaven',
    AVEN_FINANCE: 'avenfinance',
    WITH_AVEN: 'withaven',
    BANKRATE: 'bankrate',
    DEBT: 'debt',
    START: 'start',
    ABTESTV1: 'abtestv1',
    HOME_DEPOT: 'homedepot',
    HOME_IMPROVEMENT: 'homeimprovement',
    GUARANTEED: 'guaranteed', // for guaranteed lowest apr
    GUARANTEED_HELOC: 'guaranteedheloc', // for guaranteed lowest cost heloc
    BEST_CC: 'bestcc',
    YOUTUBE: 'youtube',
    LOVE: 'love',
    // Path to drip other products to MOAP
    NEW_OFFER: 'newoffer',
    // Used for mortgage cashback digital LP starting from 01/15/2024
    MORTGAGE_CASHBACK: 'mortgagecashback',
    // Used for mortgage cashback V2 digital LP starting from 03/28/2024
    MORTGAGE_CASHBACK_V2: 'mortgagecashbackv2',
    // Used for search app install campaign
    APP_OFFER: 'app-offer',
    TWENTY_TWENTY_FOUR: '2024',
    /** Landing page for leads from CreditKarma */
    KARMA: 'aa',
    /** Another Landing page for CreditKarm */
    KARMA_V2: 'karma',
    /** Landing page for leads from Fund.com */
    FUND_AVEN: 'fund',
    /** Landing page for leads from Experian */
    EXPERIAN: 'experian',
    /** Experian DM */
    EXPERIAN_INVITE: 'experianinvite',
    /** Simple Site A/B Test */
    CREDIT_CARD: 'creditcard',
    CREDIT_CARD_INVITE: 'creditcardinvite',
    /** Figure LP */
    FIGURE: 'figure',
    /** Rocket LP */
    ROCKET: 'rocket',
    /** Credit Card Focused LP */
    HELOC_CARD: 'heloccard',
    /** Personal Loan LP */
    PERSONAL_LOAN: 'personal-loan',
    /** LP for Guaranteed Lowest Rate */
    CARD_GUARANTEE: 'card-guarantee',
    /** LP for Guaranteed Lowest Cost HELOC */
    HELOC_GUARANTEE: 'heloc-guarantee',
    /** LP for competitors findaven */
    FIND_AVEN_HELOC: 'findavenheloc',
    LENDING_TREE: 'lendingtree',
    /** LP for home equity loan */
    HELOAN: 'heloan',
    /* LP for Rocket launchpad */
    LAUNCH_PAD: 'launchpad',
    LAUNCH_PAD_CASHOUT: 'launchpad/cashout',
    LAUNCH_PAD_CREDIT_CARD: 'launchpad/creditcard',
    LAUNCH_PAD_CASHOUT_INVITE: 'launchpad/cashout/invite',
    LAUNCH_PAD_CREDIT_CARD_INVITE: 'launchpad/creditcard/invite',
    LAUNCH_PAD_MAIL: 'launchpadmail',
    LAUNCH_PAD_MAIL_CASHOUT: 'launchpadmail/cashout',
    LAUNCH_PAD_MAIL_CREDIT_CARD: 'launchpadmail/creditcard',
    /* LP for CreditKarma */
    CREDIT_KARMA: 'creditkarma',
    CREDIT_KARMA_DISCLOSURES: 'ckdisclosures',

    // join names
    JOIN_AVEN_JOIN: 'joinavenjoin',
    AVEN_HELOC_JOIN: 'avenhelocjoin',
    AVEN_CASH_JOIN: 'avencashjoin',
    AVEN_CASH_V2_JOIN: 'avencashv2join',
    AVEN_CREDIT_JOIN: 'avencreditjoin',
    GO_AVEN_JOIN: 'goavenjoin',
    TRY_AVEN_JOIN: 'tryavenjoin',
    MEET_AVEN_JOIN: 'meetavenjoin',
    VISIT_AVEN_JOIN: 'visitavenjoin',
    FIND_AVEN_JOIN: 'findavenjoin',
    DISCOVER_AVEN_JOIN: 'discoveravenjoin',
    SALUTATIONS_AVEN_JOIN: 'salutationsavenjoin',
    AVEN_FINANCE_JOIN: 'avenfinancejoin',
    WITH_AVEN_JOIN: 'withavenjoin',
    BANKRATE_JOIN: 'bankratejoin',
    DEBT_JOIN: 'debtjoin',
    START_JOIN: 'startjoin',
    ABTESTV1_JOIN: 'abtestv1join',
}

// TODO: clean up inactive/unused paths
export const experimentPageBasePaths = {
    JOIN_AVEN: `/${experimentPageNames.JOIN_AVEN}`,
    AVEN_HELOC: `/${experimentPageNames.AVEN_HELOC}`,
    AVEN_CASH: `/${experimentPageNames.AVEN_CASH}`,
    AVEN_CASH_V2: `/${experimentPageNames.AVEN_CASH_V2}`,
    AVEN_CREDIT: `/${experimentPageNames.AVEN_CREDIT}`,
    GO_AVEN: `/${experimentPageNames.GO_AVEN}`,
    MEET_AVEN: `/${experimentPageNames.MEET_AVEN}`,
    GUARANTEED_HELOC: `/${experimentPageNames.GUARANTEED_HELOC}`,
    GUARANTEED: `/${experimentPageNames.GUARANTEED}`,
    // TRY_AVEN -> Used by DM batch 56E starting from 11/07/2024
    TRY_AVEN: `/${experimentPageNames.TRY_AVEN}`,
    VISIT_AVEN: `/${experimentPageNames.VISIT_AVEN}`,
    FIND_AVEN: `/${experimentPageNames.FIND_AVEN}`,
    DISCOVER_AVEN: `/${experimentPageNames.DISCOVER_AVEN}`,
    SALUTATIONS_AVEN: `/${experimentPageNames.SALUTATIONS_AVEN}`,
    AVEN_FINANCE: `/${experimentPageNames.AVEN_FINANCE}`,
    WITH_AVEN: `/${experimentPageNames.WITH_AVEN}`,
    BANKRATE: `/${experimentPageNames.BANKRATE}`,
    DEBT: `/${experimentPageNames.DEBT}`,
    START: `/${experimentPageNames.START}`,
    ABTESTV1: `/${experimentPageNames.ABTESTV1}`,
    HOME_DEPOT: `/${experimentPageNames.HOME_DEPOT}`,
    HOME_IMPROVEMENT: `/${experimentPageNames.HOME_IMPROVEMENT}`,
    BEST_CC: `/${experimentPageNames.BEST_CC}`,
    YOUTUBE: `/${experimentPageNames.YOUTUBE}`,
    LOVE: `/${experimentPageNames.LOVE}`,
    // Path to drip other products to MOAP
    NEW_OFFER: `/${experimentPageNames.NEW_OFFER}`,
    // MORTGAGE_CASHBACK -> Used for mortgage cashback digital LP starting from 01/15/2024
    MORTGAGE_CASHBACK: `/${experimentPageNames.MORTGAGE_CASHBACK}`,
    // MORTGAGE_CASHBACK_V2 -> Used for mortgage cashback digital V2 LP starting from 03/28/2024
    MORTGAGE_CASHBACK_V2: `/${experimentPageNames.MORTGAGE_CASHBACK_V2}`,
    // Used for search app install campaign
    APP_OFFER: `/${experimentPageNames.APP_OFFER}`,
    TWENTY_TWENTY_FOUR: `/${experimentPageNames.TWENTY_TWENTY_FOUR}`,
    /** Landing page for leads from CreditKarma */
    KARMA: `/${experimentPageNames.KARMA}`,
    /** Another Landing page for CreditKarm */
    KARMA_V2: `/${experimentPageNames.KARMA_V2}`,
    /** Landing page for leads from Fund.com */
    FUND_AVEN: `/${experimentPageNames.FUND_AVEN}`,
    /** Landing page for leads from Experian */
    EXPERIAN: `/${experimentPageNames.EXPERIAN}`,
    EXPERIAN_INVITE: `/${experimentPageNames.EXPERIAN_INVITE}`,
    /** Simple Site A/B Test */
    CREDIT_CARD: `/${experimentPageNames.CREDIT_CARD}`,
    CREDIT_CARD_INVITE: `/${experimentPageNames.CREDIT_CARD_INVITE}`,
    /** Figure comparison LP */
    FIGURE: `/${experimentPageNames.FIGURE}`,
    /** Rocket comparison LP */
    ROCKET: `/${experimentPageNames.ROCKET}`,
    /** Credit Card Focused LP */
    HELOC_CARD: `/${experimentPageNames.HELOC_CARD}`,
    /** Personal Loan LP */
    PERSONAL_LOAN: `/${experimentPageNames.PERSONAL_LOAN}`,
    /** LP for Guaranteed Lowest Rate */
    CARD_GUARANTEE: `/${experimentPageNames.CARD_GUARANTEE}`,
    /** LP for Guaranteed Lowest Cost HELOC */
    HELOC_GUARANTEE: `/${experimentPageNames.HELOC_GUARANTEE}`,
    /** LP for competitors findaven */
    FIND_AVEN_HELOC: `/${experimentPageNames.FIND_AVEN_HELOC}`,
    LENDING_TREE: `/${experimentPageNames.LENDING_TREE}`,
    /** LP for home equity loan */
    HELOAN: `/${experimentPageNames.HELOAN}`,
    /* LP for Rocket launchpad */
    LAUNCH_PAD: `/${experimentPageNames.LAUNCH_PAD}`,
    LAUNCH_PAD_CASHOUT: `/${experimentPageNames.LAUNCH_PAD_CASHOUT}`,
    LAUNCH_PAD_CREDIT_CARD: `/${experimentPageNames.LAUNCH_PAD_CREDIT_CARD}`,
    LAUNCH_PAD_CASHOUT_INVITE: `/${experimentPageNames.LAUNCH_PAD_CASHOUT_INVITE}`,
    LAUNCH_PAD_CREDIT_CARD_INVITE: `/${experimentPageNames.LAUNCH_PAD_CREDIT_CARD_INVITE}`,
    LAUNCH_PAD_MAIL: `/${experimentPageNames.LAUNCH_PAD_MAIL}`,
    LAUNCH_PAD_MAIL_CASHOUT: `/${experimentPageNames.LAUNCH_PAD_MAIL_CASHOUT}`,
    LAUNCH_PAD_MAIL_CREDIT_CARD: `/${experimentPageNames.LAUNCH_PAD_MAIL_CREDIT_CARD}`,
    /* LP for CreditKarma */
    CREDIT_KARMA: `/${experimentPageNames.CREDIT_KARMA}`,
    CREDIT_KARMA_DISCLOSURES: `/${experimentPageNames.CREDIT_KARMA_DISCLOSURES}`,
} as const
export type ExperimentBasePathKeys = keyof typeof experimentPageBasePaths
export type ExperimentBasePaths = typeof experimentPageBasePaths[ExperimentBasePathKeys] | typeof defaultPagePaths[keyof typeof defaultPagePaths]

// TODO: understand what is/was the use case for '/join' and potentially clean them up
export const experimentPageJoinPaths = {
    JOIN_AVEN_JOIN: `/${experimentPageNames.JOIN_AVEN}/join`,
    AVEN_HELOC_JOIN: `/${experimentPageNames.AVEN_HELOC}/join`,
    AVEN_CASH_JOIN: `/${experimentPageNames.AVEN_CASH}/join`,
    AVEN_CASH_V2_JOIN: `/${experimentPageNames.AVEN_CASH_V2}/join`,
    AVEN_CREDIT_JOIN: `/${experimentPageNames.AVEN_CREDIT}/join`,
    GO_AVEN_JOIN: `/${experimentPageNames.GO_AVEN}/join`,
    MEET_AVEN_JOIN: `/${experimentPageNames.MEET_AVEN}/join`,
    TRY_AVEN_JOIN: `/${experimentPageNames.TRY_AVEN}/join`,
    VISIT_AVEN_JOIN: `/${experimentPageNames.VISIT_AVEN}/join`,
    FIND_AVEN_JOIN: `/${experimentPageNames.FIND_AVEN}/join`,
    DISCOVER_AVEN_JOIN: `/${experimentPageNames.DISCOVER_AVEN}/join`,
    SALUTATIONS_AVEN_JOIN: `/${experimentPageNames.SALUTATIONS_AVEN}/join`,
    AVEN_FINANCE_JOIN: `/${experimentPageNames.AVEN_FINANCE}/join`,
    WITH_AVEN_JOIN: `/${experimentPageNames.WITH_AVEN}/join`,
    BANKRATE_JOIN: `/${experimentPageNames.BANKRATE}/join`,
    DEBT_JOIN: `/${experimentPageNames.DEBT_JOIN}/join`,
    START_JOIN: `/${experimentPageNames.START_JOIN}/join`,
    ABTESTV1_JOIN: `/${experimentPageNames.ABTESTV1_JOIN}/join`,
} as const
export type ExperimentJoinPathKeys = keyof typeof experimentPageJoinPaths
export type ExperimentJoinPaths = typeof experimentPageJoinPaths[ExperimentJoinPathKeys] | typeof defaultPagePaths[keyof typeof defaultPagePaths]

export const experimentPagePaths = {
    // experiment page base paths
    ...experimentPageBasePaths,

    // experiment page join paths
    ...experimentPageJoinPaths,
}

const LandingPage = () => import(/* webpackChunkName: "postAug2022NewMargin-experiment" */ '@/experiments/src/pages/marketing/LandingPage.vue')
const LandingPageContainer = () => import(/* webpackChunkName: "landingPage" */ '@/experiments/src/pages/marketing/LandingPageContainer.vue')

const getDefaultLandingPageLazyLoad = () => {
    return LandingPage
}

const getLandingPageContainerLazyLoad = () => {
    return LandingPageContainer
}

const getCreditKarmaRouteConfig = (scrollToDisclosures = false) => ({
    component: () => import(/* webpackChunkName: "creditKarma" */ '@/experiments/src/pages/marketing/LandingPage.vue'),
    props: {
        // Matching props from /creditcard:
        // https://github.com/heraclescorp/heracles/blob/3503198c25d69dd80f86955a2e724be761dad220/aven_frontend/aven/src/experiments/src/routes/marketingRoutes.ts#L595
        codeRequired: false,
        showForgotCodeOption: false,
        requiresLogViewEvent: true,
        scrollToDisclosures,
    },
    meta: { public: true },
})

const landingPageRoutes = (): AvenRouteConfig[] => [
    {
        path: marketingPagePaths.LANDING,
        name: marketingPageNames.LANDING,
        component: getLandingPageContainerLazyLoad(),
        props: {
            codeRequired: false,
            showForgotCodeOption: true,
            requiresLogViewEvent: true,
        },
        meta: { public: true },
    },
    {
        path: marketingPagePaths.PIF_SHARED_LINK_LANDING,
        name: marketingPageNames.PIF_SHARED_LINK_LANDING,
        props: {
            requiresLogViewEvent: false, // not necessary to implement for redirect paths
        },
        meta: { public: true },
        redirect: (to) => {
            appSessionStorage.setItem(sessionStorageKey.pifInviteCode, to.params.p)
            window.logEvent('event_pif_code_used', { pifInviteCode: to.params.p })
            return { path: marketingPageNames.PIF_LANDING, query: { pifinvitecode: to.params.p } }
        },
    },
    {
        path: marketingPagePaths.PIF_SHARED_LOVE_LANDING,
        name: marketingPageNames.PIF_SHARED_LOVE_LANDING,
        props: {
            requiresLogViewEvent: false, // not necessary to implement for redirect paths
        },
        meta: { public: true },
        redirect: (to) => {
            appSessionStorage.setItem(sessionStorageKey.pifInviteCode, to.params.p)
            window.logEvent('event_pif_code_used', { pifInviteCode: to.params.p })
            return { path: marketingPageNames.PIF_LANDING, query: { pifinvitecode: to.params.p } }
        },
    },
    // DO NOT CHANGE the PIF_LANDING component or path. We share PIF links on Facebook using their resolved format:
    // https://aven.com/linkLanding?pifinvitecode=SAM1a2b3c
    // That allows us to provide specific open graph meta data on *only* PIF pages and nothing else.
    // Changing the path will break those links.
    {
        path: marketingPagePaths.PIF_LANDING,
        name: marketingPageNames.PIF_LANDING,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: true, showForgotCodeOption: true, requiresLogViewEvent: true, isPifLanding: true, pifRewardLanding: PifRewardType.classicPif },
        meta: { public: true },
    },
    {
        path: marketingPagePaths.PIF_TRAVEL_LANDING,
        name: marketingPageNames.PIF_TRAVEL_LANDING,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: true, showForgotCodeOption: true, requiresLogViewEvent: true, isPifLanding: true, pifRewardLanding: PifRewardType.travelRewardsV0 },
        meta: { public: true },
    },
    {
        path: marketingPagePaths.PIF_TRAVEL_BAHAMAS,
        name: marketingPageNames.PIF_TRAVEL_BAHAMAS,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true, isPifLanding: true, pifRewardLanding: PifRewardType.travelTicketsBahamas },
        meta: { public: true },
    },
    {
        path: marketingPagePaths.LANDING_JOIN,
        name: marketingPageNames.LANDING_JOIN,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: true, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: marketingPagePaths.LANDING_INVITE,
        name: marketingPageNames.LANDING_INVITE,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: true, showForgotCodeOption: true, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: marketingPagePaths.LINK_PREVIEW_HELOC_CREDIT_CARD,
        name: marketingPageNames.LINK_PREVIEW_HELOC_CREDIT_CARD,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: true, requiresLogViewEvent: true, isLinkPreviewHelocCreditCard: true },
        meta: { public: true },
    },
    {
        path: marketingPagePaths.LINK_PREVIEW_COMPARE_HELOC,
        name: marketingPageNames.LINK_PREVIEW_COMPARE_HELOC,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: true, requiresLogViewEvent: true, isLinkPreviewCompareHeloc: true },
        meta: { public: true },
    },
    {
        path: marketingPagePaths.LINK_PREVIEW_CLOSE_FAST,
        name: marketingPageNames.LINK_PREVIEW_CLOSE_FAST,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: true, requiresLogViewEvent: true, isLinkPreviewCloseFast: true },
        meta: { public: true },
    },
    {
        path: marketingPagePaths.LINK_PREVIEW_NOTARY_ONLINE,
        name: marketingPageNames.LINK_PREVIEW_NOTARY_ONLINE,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: true, requiresLogViewEvent: true, isLinkPreviewNotaryOnline: true },
        meta: { public: true },
    },
    {
        path: marketingPagePaths.LANDING_UCC,
        name: marketingPageNames.LANDING_UCC,
        component: () => import(/* webpackChunkName: "ucc-landing" */ '@/experiments/src/pages/marketing/ucc/LandingPage.vue'),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
]

// For new landing page referrer paths added here,
// please include the new referrer to ACTIVE_NON_DM_REFERRERS in aven_backend/src/entity/experimentSpec.ts
// TODO: clean up inactive/unused routes
const landingExperimentPageRoutes = (): AvenRouteConfig[] => [
    {
        path: experimentPagePaths.HOME_DEPOT,
        name: experimentPageNames.HOME_DEPOT,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.HOME_IMPROVEMENT,
        name: experimentPageNames.HOME_IMPROVEMENT,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.VISIT_AVEN,
        name: experimentPageNames.VISIT_AVEN,
        component: () => import(/* webpackChunkName: "hero-focus-experiment" */ '@/experiments/src/pages/marketing/HeroFocusLandingPage.vue'),
        props: { codeRequired: true, showForgotCodeOption: false, requiresLogViewEvent: true, heroComponentName: 'heroHelocFocus' },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.GUARANTEED,
        name: experimentPageNames.GUARANTEED,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.JOIN_AVEN,
        name: experimentPageNames.JOIN_AVEN,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: true, showForgotCodeOption: false, requiresLogViewEvent: true, heroComponentName: 'heroJoinAndMeet' },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.MEET_AVEN,
        name: experimentPageNames.MEET_AVEN,
        component: () => import(/* webpackChunkName: "hero-focus-experiment" */ '@/experiments/src/pages/marketing/HeroFocusLandingPage.vue'),
        props: { codeRequired: true, showForgotCodeOption: false, requiresLogViewEvent: true, heroComponentName: 'heroHelocFocus' },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.AVEN_CASH,
        name: experimentPageNames.AVEN_CASH,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true, heroComponentName: 'heroCashAndHeloc' },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.AVEN_CASH_V2,
        name: experimentPageNames.AVEN_CASH_V2,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true, heroComponentName: 'heroCashAndHeloc' },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.AVEN_HELOC,
        name: experimentPageNames.AVEN_HELOC,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true, heroComponentName: 'heroCashAndHeloc' },
        meta: { public: true },
    },
    {
        // TRY_AVEN -> Used by DM batch 56E starting from 11/07/2024
        path: experimentPagePaths.TRY_AVEN,
        name: experimentPageNames.TRY_AVEN,
        component: () => import(/* webpackChunkName: "try-aven-landing-page" */ '@/experiments/src/pages/marketing/TryAvenLandingPage.vue'),
        props: { codeRequired: true, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.TRY_AVEN_JOIN,
        name: experimentPageNames.TRY_AVEN_JOIN,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.GO_AVEN,
        name: experimentPageNames.GO_AVEN,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: true, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.GO_AVEN_JOIN,
        name: experimentPageNames.GO_AVEN_JOIN,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.DISCOVER_AVEN,
        name: experimentPageNames.DISCOVER_AVEN,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: true, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.DISCOVER_AVEN_JOIN,
        name: experimentPageNames.DISCOVER_AVEN_JOIN,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.WITH_AVEN,
        name: experimentPageNames.WITH_AVEN,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: true, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.WITH_AVEN_JOIN,
        name: experimentPageNames.WITH_AVEN_JOIN,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.BANKRATE,
        name: experimentPageNames.BANKRATE,
        component: () => import(/* webpackChunkName: "hero-focus-experiment" */ '@/experiments/src/pages/marketing/HeroFocusLandingPage.vue'),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true, heroComponentName: 'heroHelocFocus' },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.BANKRATE_JOIN,
        name: experimentPageNames.BANKRATE_JOIN,
        component: () => import(/* webpackChunkName: "hero-focus-experiment" */ '@/experiments/src/pages/marketing/HeroFocusLandingPage.vue'),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true, heroComponentName: 'heroHelocFocus' },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.GUARANTEED_HELOC,
        name: experimentPageNames.GUARANTEED_HELOC,
        component: () => import(/* webpackChunkName: "hero-focus-experiment" */ '@/experiments/src/pages/marketing/HeroFocusLandingPage.vue'),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true, heroComponentName: 'bankrate' },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.DEBT,
        name: experimentPageNames.DEBT,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.DEBT_JOIN,
        name: experimentPageNames.DEBT_JOIN,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.START,
        name: experimentPageNames.START,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.START_JOIN,
        name: experimentPageNames.START_JOIN,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.AVEN_FINANCE,
        name: experimentPageNames.AVEN_FINANCE,
        component: () => import(/* webpackChunkName: "aven-finance-experiment" */ '@/experiments/src/pages/marketing/StatedUsageLandingPage.vue'),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.FIND_AVEN,
        name: experimentPageNames.FIND_AVEN,
        component: () => import(/* webpackChunkName: "hero-focus-experiment" */ '@/experiments/src/pages/marketing/HeroFocusLandingPage.vue'),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true, heroComponentName: 'heroHelocFocus' },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.BEST_CC,
        name: experimentPageNames.BEST_CC,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true, heroComponentName: 'bestCc' },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.YOUTUBE,
        name: experimentPageNames.YOUTUBE,
        component: () => import(/* webpackChunkName: "video-landing-page" */ '@/experiments/src/pages/marketing/VideoLandingPage.vue'),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.LOVE,
        name: experimentPageNames.LOVE,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.NEW_OFFER,
        name: experimentPageNames.NEW_OFFER,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.MORTGAGE_CASHBACK,
        name: experimentPageNames.MORTGAGE_CASHBACK,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.MORTGAGE_CASHBACK_V2,
        name: experimentPageNames.MORTGAGE_CASHBACK_V2,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.APP_OFFER,
        name: experimentPageNames.APP_OFFER,
        component: () => import(/* webpackChunkName: "hero-focus-experiment" */ '@/experiments/src/pages/marketing/HeroFocusLandingPage.vue'),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true, heroComponentName: 'heroHelocFocus' },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.TWENTY_TWENTY_FOUR,
        name: experimentPageNames.TWENTY_TWENTY_FOUR,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.KARMA,
        name: experimentPageNames.KARMA,
        component: () => import(/* webpackChunkName: "hero-focus-experiment" */ '@/experiments/src/pages/marketing/HeroFocusLandingPage.vue'),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true, heroComponentName: 'creditKarma' },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.FUND_AVEN,
        name: experimentPageNames.FUND_AVEN,
        component: () => import(/* webpackChunkName: "hero-focus-experiment" */ '@/experiments/src/pages/marketing/HeroFocusLandingPage.vue'),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true, heroComponentName: 'heroHelocFocus' },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.EXPERIAN,
        name: experimentPageNames.EXPERIAN,
        component: () => import(/* webpackChunkName: "experian-landing-page" */ '@/experiments/src/pages/marketing/ExperianLandingPage.vue'),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.EXPERIAN_INVITE,
        name: experimentPageNames.EXPERIAN_INVITE,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: true, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.KARMA_V2,
        name: experimentPageNames.KARMA_V2,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.CREDIT_CARD,
        name: experimentPageNames.CREDIT_CARD,
        component: getLandingPageContainerLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.CREDIT_CARD_INVITE,
        name: experimentPageNames.CREDIT_CARD_INVITE,
        component: getLandingPageContainerLazyLoad(),
        props: { codeRequired: true, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.FIGURE,
        name: experimentPageNames.FIGURE,
        component: () => import(/* webpackChunkName: "figure-landing-page" */ '@/experiments/src/pages/marketing/FigureLandingPage.vue'),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.ROCKET,
        name: experimentPageNames.ROCKET,
        component: () => import(/* webpackChunkName: "rocket-landing-page" */ '@/experiments/src/pages/marketing/RocketLandingPage.vue'),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.HELOC_CARD,
        name: experimentPageNames.HELOC_CARD,
        component: () => import(/* webpackChunkName: "heloc-card-landing-page" */ '@/experiments/src/pages/marketing/HelocCardLandingPage.vue'),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.PERSONAL_LOAN,
        name: experimentPageNames.PERSONAL_LOAN,
        component: () => import(/* webpackChunkName: "personal-loan-landing-page" */ '@/experiments/src/pages/marketing/PersonalLoanLandingPage.vue'),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.CARD_GUARANTEE,
        name: experimentPageNames.CARD_GUARANTEE,
        component: () => import(/* webpackChunkName: "card-guarantee-landing-page" */ '@/experiments/src/pages/marketing/CardGuaranteeLandingPage.vue'),
        props: { requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.HELOC_GUARANTEE,
        name: experimentPageNames.HELOC_GUARANTEE,
        component: () => import(/* webpackChunkName: "heloc-guarantee-landing-page" */ '@/experiments/src/pages/marketing/HelocGuaranteeLandingPage.vue'),
        props: { requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.FIND_AVEN_HELOC,
        name: experimentPageNames.FIND_AVEN_HELOC,
        component: () => import(/* webpackChunkName: "findaven-heloc-landing-page" */ '@/experiments/src/pages/marketing/CompetitorsLandingPage.vue'),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.LENDING_TREE,
        name: experimentPageNames.LENDING_TREE,
        component: getDefaultLandingPageLazyLoad(),
        props: { codeRequired: false, showForgotCodeOption: false, requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.HELOAN,
        name: experimentPageNames.HELOAN,
        component: () => import(/* webpackChunkName: "heloan" */ '@/pages/origination/HeloanLandingPage.vue'),
        meta: { public: true },
        // TODO:
        props: { requiresLogViewEvent: true },
    },
    {
        path: experimentPagePaths.LAUNCH_PAD,
        name: experimentPageNames.LAUNCH_PAD,
        component: () => import(/* webpackChunkName: "launchpad" */ '@/pages/marketing/LaunchPad.vue'),
        props: { requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.LAUNCH_PAD_CREDIT_CARD,
        name: experimentPageNames.LAUNCH_PAD_CREDIT_CARD,
        component: () => import(/* webpackChunkName: "launchpad" */ '@/pages/marketing/LaunchPadGetStarted.vue'),
        props: { requiresLogViewEvent: true, showMailOfferLink: true, mailOfferLink: { path: experimentPagePaths.LAUNCH_PAD_CREDIT_CARD_INVITE } },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.LAUNCH_PAD_CASHOUT,
        name: experimentPageNames.LAUNCH_PAD_CASHOUT,
        component: () => import(/* webpackChunkName: "launchpad" */ '@/pages/marketing/LaunchPadGetStarted.vue'),
        props: { requiresLogViewEvent: true, showMailOfferLink: true, mailOfferLink: { path: experimentPagePaths.LAUNCH_PAD_CASHOUT_INVITE } },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.LAUNCH_PAD_CREDIT_CARD_INVITE,
        name: experimentPageNames.LAUNCH_PAD_CREDIT_CARD_INVITE,
        component: () => import(/* webpackChunkName: "launchpad" */ '@/pages/marketing/LaunchPadGetStarted.vue'),
        props: { requiresLogViewEvent: true, codeRequired: true, showForgotCodeOption: true, forgotCodeLink: experimentPagePaths.LAUNCH_PAD_CREDIT_CARD },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.LAUNCH_PAD_CASHOUT_INVITE,
        name: experimentPageNames.LAUNCH_PAD_CASHOUT_INVITE,
        component: () => import(/* webpackChunkName: "launchpad" */ '@/pages/marketing/LaunchPadGetStarted.vue'),
        props: { requiresLogViewEvent: true, codeRequired: true, showForgotCodeOption: true, forgotCodeLink: experimentPagePaths.LAUNCH_PAD_CASHOUT },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.LAUNCH_PAD_MAIL,
        name: experimentPageNames.LAUNCH_PAD_MAIL,
        component: () => import(/* webpackChunkName: "launchpad" */ '@/pages/marketing/LaunchPad.vue'),
        props: { requiresLogViewEvent: true },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.LAUNCH_PAD_MAIL_CREDIT_CARD,
        name: experimentPageNames.LAUNCH_PAD_MAIL_CREDIT_CARD,
        component: () => import(/* webpackChunkName: "launchpad" */ '@/pages/marketing/LaunchPadGetStarted.vue'),
        props: { requiresLogViewEvent: true, codeRequired: true, showForgotCodeOption: false },
        meta: { public: true },
    },
    {
        path: experimentPagePaths.LAUNCH_PAD_MAIL_CASHOUT,
        name: experimentPageNames.LAUNCH_PAD_MAIL_CASHOUT,
        component: () => import(/* webpackChunkName: "launchpad" */ '@/pages/marketing/LaunchPadGetStarted.vue'),
        props: { requiresLogViewEvent: true, codeRequired: true, showForgotCodeOption: false },
        meta: { public: true },
    },

    {
        path: experimentPagePaths.CREDIT_KARMA,
        name: experimentPageNames.CREDIT_KARMA,
        ...getCreditKarmaRouteConfig(),
    },
    {
        path: experimentPagePaths.CREDIT_KARMA_DISCLOSURES,
        name: experimentPageNames.CREDIT_KARMA_DISCLOSURES,
        ...getCreditKarmaRouteConfig(true),
    },
]

/**
 * NOTES: funky rendering? check out landingPageUtil.ts
 */

export const experimentMarketingPageRoutes = (): RouteConfig[] => [...landingPageRoutes(), ...landingExperimentPageRoutes()]
