import {
    FloodInsuranceStatus,
    PendingDocumentRequestsInfo,
    PendingDocumentTaskType,
    ProcessUpdatedApplicantResponse,
    UploadPendingDocumentPayload,
    GetVerifyCurrentRentDocumentOptionsResponse,
    IncomeVerificationAllowedDocs,
    IncomeVerificationDocument,
    IncomeVerificationResultType,
    MaritalStatus,
    ResidenceType,
    IUpdatePersonalPropertyAttestationInfoPayload,
    SubmitPersonalPropertyAttestationInfoResponse,
    SignDocumentPayload,
    DocumentAckMetadataPayload,
    GOOGLE_TAG_MANAGER_EVENT_NAME,
    AssetVerificationPurpose,
    AssetVerificationResultType,
    NextHomeApplicationAction,
    ValidDocuSignDocType,
} from 'aven_types'
import { BackendResponse, coApplicantHttpClient, httpClient, runWithRetryLogic } from '@/utils/http-client'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { logger } from '@/utils/logger'
import { getUserTraits, maybeInitFacebook, setSessionMetadata } from '@/services/marketing'
import { ParentApplicantRelationshipType } from '@/services/homeApi'
import { AxiosResponse } from 'axios'
import { getMetadata, persistSessionData } from '@/services/sessionService'
import { AccountClosureReason, CustomerSurveyType, LoanApplicationType, WithdrawOrDeclineReason } from '@/utils/constants'
import { maybeSetUnderwritingMetadata, UnderwritingMetaData } from '@/utils/uwMetaData'
import { TrustpilotBusinessUnits, TrustpilotBusinessUnitsGetResponse } from '@/utils/trustpilot'
import { LoanTermsWithMetadata } from '@/composables/useFetchApplicantReturningOGProductDetails'
import FormData from 'form-data'
import pick from 'lodash/pick'

// Keep in sync with aven_backend/src/manager/calendly/calendlyManager.ts
enum CreateCallBookingLinkError {
    InvalidPhoneNumber = 'INVALID_PHONE_NUMBER',
    NoBookingsAvailable = 'NO_BOOKINGS_AVAILABLE',
}

// keep in sync with https://github.com/heraclescorp/heracles/blob/860355c4b715685932f940468e433042def1a02f/aven_backend/src/controller/applicant/chatbotPredictionController.ts#L31-L34
export enum ZendeskFaqPredictionFeedbackEnum {
    accepted = 'accepted',
    denied = 'denied',
}

const postRoute = async (route: string, postBody: string | object, isCoApplicant: boolean = false) => {
    if (isCoApplicant) {
        return await coApplicantHttpClient.post(route, postBody)
    }
    return await httpClient.post(route, postBody)
}

export const trackClick = async (link: string) => {
    return await httpClient.post('/ana/clk', { link })
}

export interface ApplicantReturningPayload {
    returnToken2: string
    loanApplicationStatus: string
    loanApplicationId: number
}
const getApplicantReturning = async (returnToken: string): Promise<BackendResponse<ApplicantReturningPayload>> => {
    const response = await httpClient.get('/applicantReturning', {
        params: {
            returnToken,
        },
    })
    saveApplicantSessionResponse(response)
    appSessionStorage.setItem(sessionStorageKey.returnToken2, returnToken)
    setSessionMetadata(getUserTraits())
    maybeInitFacebook(response.data.payload.facebookExternalId)
    return response
}

const getApplicantReturningToDocumentUploadPortal = async (returnToken: string) => {
    const response = await httpClient.get('/applicantReturningToDocumentUploadPortal', {
        params: {
            returnToken,
        },
    })
    saveApplicantSessionResponse(response)
    appSessionStorage.setItem(sessionStorageKey.returnToken2, returnToken)
    setSessionMetadata(getUserTraits())
    maybeInitFacebook(response.data.payload.facebookExternalId)
    return response
}

const getApplicantReturningHMDA = async (returnToken: string) => {
    const response = await httpClient.get('/applicantReturningHMDA', {
        params: {
            returnToken,
        },
    })
    saveApplicantSessionResponse(response)
    appSessionStorage.setItem(sessionStorageKey.returnToken2, returnToken)
    setSessionMetadata(getUserTraits())
    maybeInitFacebook(response.data.payload.facebookExternalId)
    return response
}

/* Update applicant and loanApplication types when we move those to aven_types */
const getApplicantReturningOGProductExplanation = async (
    returnToken: string
): Promise<BackendResponse<{ loanApplication: { status: string }; applicant: unknown; returnApplicantData: unknown; offers: LoanTermsWithMetadata[]; uwMetadata: UnderwritingMetaData }>> => {
    const response = await httpClient.get('/applicantReturningOGProductDetails', {
        params: {
            returnToken,
        },
    })
    saveApplicantSessionResponse({ data: { payload: response.data.payload.returnApplicantData } })
    appSessionStorage.setItem(sessionStorageKey.returnToken2, returnToken)
    setSessionMetadata(getUserTraits())
    maybeInitFacebook(response.data.payload.facebookExternalId)
    return response
}

export enum ApplicantReturningForTestingReason {
    AUTO = 'AUTO',
}

const getApplicantReturningForTesting = async (returnToken: string) => {
    const response = await httpClient.get('/applicantReturningForTesting', {
        params: {
            returnToken,
        },
    })
    saveApplicantSessionResponse(response)
    appSessionStorage.setItem(sessionStorageKey.returnToken2, returnToken)
    setSessionMetadata(getUserTraits())
    maybeInitFacebook(response.data.payload.facebookExternalId)
    return response
}

const saveApplicantSessionResponse = (response: { data: any }) => {
    const data = response.data
    const payload = data?.payload
    appSessionStorage.setItemIfPresent(sessionStorageKey.loanApplicationId, payload?.loanApplicationId, (id) => id.toString())
    appSessionStorage.setItemIfPresent(sessionStorageKey.phoneNumber, payload?.phoneNumber)
    appSessionStorage.setItemIfPresent(sessionStorageKey.email, payload?.email)

    if (payload && payload.jwt) {
        logger.info(`Saving applicant session response - applicant id: ${payload.applicantId} / loan application id: ${payload.loanApplicationId}`)

        appSessionStorage.setItemIfPresent(sessionStorageKey.jwtTokens, payload.jwt, (jwt) => JSON.stringify(jwt))
        appSessionStorage.setItemIfPresent(sessionStorageKey.coApplicantJwtTokens, payload.coApplicantJwtTokens, (jwt) => JSON.stringify(jwt))

        appSessionStorage.setItemIfPresent(sessionStorageKey.applicantId, payload.applicantId, (id) => id.toString())
        appSessionStorage.setItemIfPresent(sessionStorageKey.applicantType, payload.applicantType)

        appSessionStorage.setItemIfPresent(sessionStorageKey.experimentsOverrides, JSON.stringify(payload.experimentsOverrides))
        maybeSetUnderwritingMetadata(payload.underwritingMetadata)

        appSessionStorage.setItemIfPresent(sessionStorageKey.inviteCode, payload.inviteCode)

        appSessionStorage.setItemIfPresent(sessionStorageKey.inviteType, payload.inviteType)

        appSessionStorage.setItemIfPresent(sessionStorageKey.pifBonus, payload.pifBonus, (bonus) => bonus?.toString() || '')

        appSessionStorage.setItemIfPresent(sessionStorageKey.pifSenderName, payload.pifSenderName)

        appSessionStorage.setItemIfPresent(sessionStorageKey.phoneNumber, payload.phoneNumber)
        appSessionStorage.setItemIfPresent(sessionStorageKey.coApplicantPhoneNumber, payload.coApplicantPhoneNumber)

        appSessionStorage.setItemIfPresent(sessionStorageKey.firstName, payload.firstName)
        appSessionStorage.setItemIfPresent(sessionStorageKey.lastName, payload.lastName)

        appSessionStorage.setItemIfPresent(sessionStorageKey.coApplicantFirstName, payload.coApplicantFirstName)
        appSessionStorage.setItemIfPresent(sessionStorageKey.coApplicantLastName, payload.coApplicantLastName)

        appSessionStorage.setItemIfPresent(sessionStorageKey.statedIncome, payload.statedIncome, (income) => income.toString())
        appSessionStorage.setItemIfPresent(sessionStorageKey.statedAnnualRentalIncome, payload.statedAnnualRentalIncome, (income) => income.toString())

        appSessionStorage.setItemIfPresent(sessionStorageKey.coApplicantStatedIncome, payload.coApplicantStatedIncome, (income) => income.toString())

        appSessionStorage.setItemIfPresent(sessionStorageKey.applicantMaritalStatus, payload.maritalStatus)
        appSessionStorage.setItemIfPresent(sessionStorageKey.coApplicantMaritalStatus, payload.coApplicantMaritalStatus)

        appSessionStorage.setItemIfPresent(sessionStorageKey.applicantSubmittedEmployer, payload.hasSubmittedEmployer, (val) => val.toString())
        appSessionStorage.setItemIfPresent(sessionStorageKey.coApplicantSubmittedEmployer, payload.hasCoApplicantSubmittedEmployer, (val) => val.toString())

        appSessionStorage.setItemIfPresent(sessionStorageKey.mloId, payload.mloId, (id) => id.toString())

        appSessionStorage.setItemIfPresent(sessionStorageKey.isInIncomeVerification, payload.isInIncomeVerification, (val) => val.toString())
        appSessionStorage.setItemIfPresent(sessionStorageKey.isInFloodInsuranceVerification, payload.isInFloodInsuranceVerification, (val) => val.toString())

        appSessionStorage.setItemIfPresent(sessionStorageKey.residenceType, payload.residenceType)
        appSessionStorage.setItemIfPresent(sessionStorageKey.addressState, payload.addressState)
    }
}

interface AbandonApplicationParams {
    wantsToAbandonCurrentApplication: boolean
    returnToken2ForPriorApplication: string
    isCoApplicant: boolean
}
const abandonApplication = async ({ wantsToAbandonCurrentApplication, returnToken2ForPriorApplication, isCoApplicant }: AbandonApplicationParams) => {
    return await httpClient.post('/origination/abandonApplication', { wantsToAbandonCurrentApplication, returnToken2ForPriorApplication, isCoApplicant })
}

const getNextHomeApplicationAction = async () => {
    return await httpClient.get<{ nextApplicationAction: NextHomeApplicationAction; type: LoanApplicationType }>('/nextApplicationAction')
}

const plaidReportFetchState = async (isCoApplicant?: boolean) => {
    if (isCoApplicant) {
        return await coApplicantHttpClient.get('/plaidReportFetchState')
    }
    return await httpClient.get('/plaidReportFetchState')
}

const getLegalDocument = async (docType: LegalDocumentTypes, password: string | undefined, applicantType: string | undefined) => {
    return await httpClient.get('/legal', {
        responseType: 'blob',
        params: {
            docType,
            password,
            applicantType,
        },
    })
}

const getHtmlLegalDocument = async (docType: LegalDocumentTypes, shouldRegenerateDoc = false, password: string | undefined) => {
    return await httpClient.get('/legalHtml', {
        params: {
            docType,
            shouldRegenerateDoc,
            password,
        },
    })
}

const getHtmlLegalDocumentForSession = async (docType: LegalDocumentTypes, policyExperiment?: string) => {
    return await httpClient.get('/legalHtml/public', {
        params: {
            docType,
            policyExperiment,
        },
    })
}

const getLegalDocumentForSession = async (docType: LegalDocumentTypes, policyExperiment?: string) => {
    return await httpClient.get('/legal/documentForSession', {
        responseType: 'blob',
        params: {
            docType,
            policyExperiment,
        },
    })
}

// signDocument just enqueues a job and returns immediately, does not guarantee signed state until async job succeeds
export const signDocument = async (docType: LegalDocumentTypes, metadata: SignDocumentPayload) => {
    return await httpClient.post('/legal/signDocument', {
        docType: docType,
        metadata: metadata,
    })
}

// Keep in sync with consts in aven_backend/src/util/legalConstants.ts
enum LegalDocumentTypes {
    hudGovDoc = 'HomeOwnershipCounselingServices',
    creditScoreDisclosure = 'CreditScoreDisclosure',
    applicantCreditScoreDisclosure = 'ApplicantCreditScoreDisclosure',
    shortFormDeedOfTrust = 'ShortFormDeedOfTrust',
    longFormDeedOfTrust = 'LongFormDeedOfTrust',
    certificationOfTrust = 'CertificationOfTrust',
    helocDeedOfTrust = 'HelocDeedOfTrust',
    lastTransferDocument = 'LastTransferDocument',
    accountAgreement = 'AccountAgreement',
    creditLineIncreaseDOTAmendment = 'CreditLineIncreaseDOTAmendment',
    noticeOfRightToCancel = 'NoticeOfRightToCancel',
    adverseActionNotice = 'AdverseActionNotice',
    fictitiousDeedOfTrust = 'FictitiousDeedOfTrust',
    earlyHELOCDisclosure = 'EarlyHELOCDisclosure',
    pricingAndTerms = 'PricingAndTerms',
    propertyValuation = 'PropertyValuation',
    appraisalWaiver = 'AppraisalWaiver',
    floodNotificationDisclosure = 'FloodNotificationDisclosure',
    irs4506cPreview = 'Irs4506cPreview',
    irs4506cSigned = 'Irs4506cSigned',
    retentionOfferAccountAgreementAddenda = 'RetentionOfferAccountAgreementAddenda',
}

const postLegalDocuments = async (docTypes: string[]) => {
    return await httpClient.post(
        '/legal',
        {
            docTypes: docTypes,
        },
        {
            timeout: 100000, // TODO: remove this timeout when https://github.com/heraclescorp/heracles/pull/13676/ is deployed
        }
    )
}

export const requestPlaidLinkToken = async (redirectUri: string) => {
    return await httpClient.post<{ linkToken: string } | undefined>('/plaid/requestLinkToken', {
        redirectUri,
    })
}

const startPlaidReportFetch = async (plaidPublicToken: string, verificationPurpose: string | null, isCoApplicant?: boolean) => {
    if (isCoApplicant) {
        return await coApplicantHttpClient.post('/startPlaidReportFetch', {
            public_token: plaidPublicToken,
            verificationPurpose,
        })
    }
    return await httpClient.post('/startPlaidReportFetch', {
        public_token: plaidPublicToken,
        verificationPurpose,
    })
}

const updateApplicantNameFromNonEntityOwnerName = async (selectedOwnerName: string) => {
    return await httpClient.post('/updateApplicantNameFromNonEntityOwnerName', { selectedOwnerName })
}

export interface IUpdateApplicantInfoPayload {
    firstName?: string
    middleName?: string
    lastName?: string
    phoneNumber?: string
    addressFromMultiFieldForm?: boolean
    dateOfBirth?: string
    ssn?: string
    email?: string
    parentApplicantRelationship?: ParentApplicantRelationshipType
}
const postUpdateApplicantFields = (postBody: IUpdateApplicantInfoPayload, isCoApplicant?: boolean) => {
    return postRoute('/updateApplicantFields', postBody, isCoApplicant)
}

interface IAddWaitlistInfoPayload {
    firstName: string
    lastName: string
    emailAddress: string
    phoneNumber: string
    addressApt: string
    addressStreet: string
    addressCity: string
    addressState: string
    addressPostalCode: string
}
const postWaitlistInfo = (postBody: IAddWaitlistInfoPayload) => {
    return postRoute('/addWaitlist', postBody)
}

const getShouldWaitlistApplicant = async () => {
    return await httpClient.get('/shouldWaitlistApplicant')
}

// Keep in sync with enum in aven_backend/src/controller/updateApplicantInfoController.ts
export enum PiiSubmissionErrors {
    TERMINAL_APPLICATION_ERROR = 'TERMINAL_APPLICATION_ERROR',
    ALREADY_SUBMITTED_BASIC_INFO_ERROR = 'ALREADY_SUBMITTED_BASIC_INFO_ERROR',
}

const getCanApplicantSubmitPii = async (): Promise<BackendResponse<any>> => {
    return httpClient.get('/canApplicantSubmitPii')
}

export interface IUpdateApplicantAddressPayload {
    addressApt?: string
    addressStreet: string
    addressCity: string
    addressState: string
    addressPostalCode: string
    residenceType: ResidenceType
    isPropertyAddress: boolean
}
const postUpdateAddress = async (postBody: IUpdateApplicantAddressPayload, isCoApplicant?: boolean): Promise<any> => {
    return await postRoute('/updateApplicantAddress', postBody, isCoApplicant)
}

const getDidUnderwritingRelatedInfoChange = async (postBody: Object): Promise<any> => {
    return await postRoute('/getDidUnderwritingRelatedInfoChange', postBody)
}

const postUpdateMaritalStatus = (maritalStatus: MaritalStatus, isCoApplicant?: boolean) => {
    let sanitizedMaritalStatus = typeof maritalStatus === 'string' ? maritalStatus : ''
    sanitizedMaritalStatus = sanitizedMaritalStatus.toUpperCase()

    return postRoute('/updateApplicantMaritalStatus', { maritalStatus: sanitizedMaritalStatus }, isCoApplicant)
}

const processUpdatedApplicant = (isCoApplicant?: boolean): Promise<BackendResponse<ProcessUpdatedApplicantResponse>> => {
    return postRoute('/processUpdatedApplicant', {}, isCoApplicant)
}

const uploadDocument = async (fileUrl: File, documentTypePath: string, documentIndex: number, isCoApplicant?: boolean, quietMode?: boolean) => {
    const formData = new FormData()
    formData.append('file', fileUrl)
    formData.append('quietMode', quietMode ? '1' : '0')
    if (isCoApplicant) {
        return await coApplicantHttpClient.post(`/uploadDocument/${documentTypePath}/${documentIndex}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            timeout: 100000,
        })
    }
    return await httpClient.post(`/uploadDocument/${documentTypePath}/${documentIndex}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        timeout: 100000,
    })
}

const getIsWaitingOnManualDocumentVerification = async (isCoApplicant?: boolean) => {
    if (isCoApplicant) {
        return await coApplicantHttpClient.get('/getIsWaitingOnManualDocumentVerification', {})
    }
    return await httpClient.get('/getIsWaitingOnManualDocumentVerification', {})
}

// This returns the HMDA collection status for the entire loan application
const getIsHMDADataCollectedForLoanApplication = async () => {
    return await httpClient.get('/getIsHMDADataCollectedForLoanApplication', {})
}

export const getAllPendingDocumentRequestsInfo = async (pendingDocumentTaskType: PendingDocumentTaskType): Promise<BackendResponse<PendingDocumentRequestsInfo>> => {
    return await httpClient.get<PendingDocumentRequestsInfo>('/getAllPendingDocumentRequestsInfo', { params: { pendingDocumentTaskType } })
}

export const uploadPendingDocument = async (payload: UploadPendingDocumentPayload): Promise<BackendResponse<undefined>> => {
    const form = new FormData()
    form.append('pendingDocumentTaskType', payload.pendingDocumentTaskType)
    if (payload.singleDocumentUploadRequestId) {
        form.append('singleDocumentUploadRequestId', payload.singleDocumentUploadRequestId.toString())
    }
    form.append('documentUploadType', payload.documentUploadType)
    if (payload.customAttributesJson) {
        form.append('customAttributesJson', payload.customAttributesJson)
    }
    form.append('file', payload.file)

    return await httpClient.post('/uploadDocument/uploadPendingDocument', form, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        timeout: 100000,
    })
}

export const getShouldDirectToIncomeDocumentUploadPortal = async (): Promise<BackendResponse<{ shouldDirectToIncomeDocumentUploadPortal: boolean }>> => {
    return await httpClient.get<{ shouldDirectToIncomeDocumentUploadPortal: boolean }>('/getShouldDirectToIncomeDocumentUploadPortal')
}

// Keep in sync with AvenAdvisorAANScreens in aven_backend/src/manager/unapprovedLoanApplicationReasonsManager.ts
export enum AvenAdvisorAANScreens {
    creditScore = 'creditScore',
    dti = 'dti',
    homeValue = 'homeValue',
    other = 'other',
}

export enum AutoAANScreen {
    licensePlate = 'licensePlate',
    vehicleModel = 'vehicleModel',
}

// Keep in sync with ReUnderwriteAANScreen in aven_backend/src/manager/unapprovedLoanApplicationReasonsManager.ts
export enum ReUnderwriteAANScreen {
    cliAndAprReductionAndBalanceSweep = 'cliAndAprReductionAndBalanceSweep',
    cli = 'cli',
}

const getUnapprovedLoanApplicationReasons = async () => {
    return await httpClient.get('/unapprovedLoanApplicationReasons')
}

const getApplicantState = async () => {
    return await httpClient.get('/getApplicantState')
}

const createAvenAdvisorAccountFromOrigination = async (): Promise<BackendResponse<{ redirectUrl: string }>> => {
    return await httpClient.post<{ redirectUrl: string }>('/createAvenAdvisorAccountFromOrigination', { consentToCreditOfferDisclosuresHash: process.env.VUE_APP_SENTRY_RELEASE })
}

const beginPayStubVerification = async (isCoApplicant?: boolean) => {
    if (isCoApplicant) {
        return await coApplicantHttpClient.get('/bank/beginPayStubVerification', {})
    }
    return await httpClient.get('/bank/beginPayStubVerification', {})
}

const beginTaxReturnVerification = async (isCoApplicant?: boolean) => {
    if (isCoApplicant) {
        return await coApplicantHttpClient.get('/bank/beginTaxReturnVerification', {})
    }
    return await httpClient.get('/bank/beginTaxReturnVerification', {})
}

export enum DocumentSignatureType {
    text = 'text',
    image = 'image',
}

const beginIrsFormVerification = async (metadata: SignDocumentPayload) => {
    return await httpClient.post('/bank/beginIrsFormVerification', {
        metadata,
    })
}

const hasStartedIrsFormVerification = async (): Promise<BackendResponse<boolean>> => {
    return await httpClient.get('/bank/hasStartedIrsFormVerification')
}

const getPrefilledInformationForMailerLead = async (inviteCode: string) => {
    return await httpClient.post('/getPrefilledInformationForMailerLead', { inviteCode })
}

const getPrefilledInformationForMethodFi = async () => {
    return await httpClient.post('/getPrefilledInformationForMethodFi')
}

const getPrefilledInformationForMethodFiWithSSNLast4 = async (ssnLast4: string) => {
    return await httpClient.post('/getPrefilledInformationForMethodFiWithSSNLast4', { ssnLast4 })
}

export const getPiiPrefill = async () => {
    return await httpClient.get('/getPiiPrefill')
}

export const getUserEmail = async (sessionId: string): Promise<BackendResponse<{ email: string | null }>> => {
    return httpClient.get('/userFeedback/getUserEmail', {
        params: {
            sessionId,
        },
    })
}

export const submitUserFeedback = async (feedbackText: string, sessionId: string, email?: string) => {
    return httpClient.post('/userFeedback/submitUserFeedback', {
        feedbackText,
        sessionId,
        email,
    })
}

const requestCallMeNow = async ({ applicantId }: { applicantId: number }): Promise<BackendResponse<{ callIsMade: string }>> => {
    return httpClient.post<{ callIsMade: string }>('/userFeedback/requestCallMeNow', {
        applicantId,
    })
}

const isCallMeBackAvailable = async ({ applicantId }: { applicantId: number }): Promise<BackendResponse<{ canStart: string }>> => {
    return httpClient.post('/userFeedback/isCallMeBackAvailable', {
        applicantId,
    })
}

const sendDataSupportUserFeedback = async (email: string, feedbackText: string) => {
    return await httpClient.post('/userFeedback/dataSupportEmail', {
        fromEmail: email,
        dataSupportText: feedbackText,
    })
}

const createTrustPilotUniqueLink = async (returnToken2: string) => {
    return httpClient.post('/createTrustPilotUniqueLink', {
        returnToken2,
    })
}

const submitPredictionCustomerFeedback = async (returnToken2: string, predictionId: number, zendeskTicketId: string, decision: ZendeskFaqPredictionFeedbackEnum) => {
    return httpClient.post('/userFeedback/prediction/submitCustomerFeedback', {
        returnToken2,
        predictionId,
        zendeskTicketId,
        decision,
    })
}

const getTrustInfo = async () => {
    return await httpClient.get('/getTrustInfo')
}

const postTrustInfoForm = async (postBody: object) => {
    return await httpClient.post('/postTrustInfoForm', postBody)
}

export type PartialApplicantInfo = { firstName: string; lastName: string }
export type PropertyAddress = {
    addressStreet: string
    addressUnit?: string
    addressCity: string
    addressState: string
    addressPostalCode: string
}
const getStatePageModules = async (
    screenName: string,
    state?: string,
    partialApplicantInfo?: PartialApplicantInfo,
    partialCoApplicantInfo?: PartialApplicantInfo,
    propertyAddress?: PropertyAddress
) => {
    const postBody = Object.fromEntries(
        Object.entries({
            screenName: screenName,
            state: state,
            partialApplicantInfo: partialApplicantInfo,
            propertyAddress: propertyAddress,
            partialCoApplicantInfo: partialCoApplicantInfo,
        }).filter(([, value]) => !!value) // Filter any non truthy value so it doesn't get sent to BE
    )

    return httpClient.post('/stateModules', postBody)
}

const isStateActive = async (twoLetterStateCode: string, streetLine?: string) => {
    // Street line is optionally passed to the BE to determine if this is a whitelisted address
    // If no address line is passed there will not be any whitelisting consideration
    return httpClient.post('/stateModules/active', { stateCode: twoLetterStateCode, streetLine })
}

const isStateSupportedForCurrentLA = async () => {
    return httpClient.post('/stateModules/supportedState')
}

const getSavingsForTicker = async (): Promise<BackendResponse<{ currentSavingsBalance: string; lastSavingsBalance: string }>> => {
    return await httpClient.get('/getSavingsForTicker')
}

const getIncomeVerificationAllowedDocs = async (employmentType: string): Promise<AxiosResponse<IncomeVerificationAllowedDocs>> => {
    const response = await httpClient.post('/getIncomeVerificationAllowedDocs', { employmentType })
    return response.data.payload
}

const initializeIncomeVerificationForNonOwnerOccupied = async (): Promise<{
    docs: IncomeVerificationDocument[]
}> => {
    const response = await httpClient.post('/initializeIncomeVerificationForNonOwnerOccupied')
    return response.data.payload
}

const getVerifyCurrentRentDocumentOptions = async (): Promise<GetVerifyCurrentRentDocumentOptionsResponse> => {
    const response = await httpClient.get('/getVerifyCurrentRentDocumentOptions')
    return response.data.payload
}

// Keep in sync with aven_backend/src/controller/stateModulesController.ts
export enum StateModules {
    /**
     * Shows a link to the document along with a mandatory checkbox and optionally a
     * subtitle
     */
    RequiredDocumentAck = 'RequiredDocumentAck',
    /**
     * Shows a link to a set of documents anda single link to download them all, along with a mandatory checkbox and optionally a
     * subtitle for the whole block
     */
    MultipleRequiredDocumentAck = 'MultipleRequiredDocumentAck',
    /**
     * Shows a link to the document and optionally a subtitle
     */
    DocumentDisclosure = 'DocumentDisclosure',
    /**
     * Just shows some text, to be used on welcome screen
     */
    WelcomeExtraInfo = 'WelcomeExtraInfo',
    /**
     * Just shows text, to be used on the footer section of any page
     */
    FooterDisclosure = 'FooterDisclosure',
}

interface ModuleValue {
    type: StateModules
    id: number
    value: any
    metadata: DocumentAckMetadataPayload
}

export interface SaveStateModuleValuesPayload {
    moduleValues: ModuleValue[]
}

const saveStateModuleValues = async (stateModuleValues: SaveStateModuleValuesPayload) => {
    return await httpClient.post('/stateModules/values', stateModuleValues)
}

// keep in line with aven_backend/src/provider/Heracles/pythonService.types.ts
enum IncomeVerificationMethod {
    experian = 'experian',
    workNumber = 'workNumber',
    plaid = 'plaid',
    manualVerification = 'manualVerification',
    all = 'all', // means to check all the available income sources
}

// keep in line with aven_backend
export enum IncomeVerificationPurpose {
    automatic = 'automatic',
    plaid = 'plaid',
    manual = 'manual',
    // TODO: should IRS be here? it's in backend/types
}

export interface IFloodInsuranceInformationPayload {
    floodInsuranceStatus: FloodInsuranceStatus
    floodZone: string
}

const getFloodInsuranceInformation = async (): Promise<IFloodInsuranceInformationPayload> => {
    const response = (await httpClient.get('origination/getFloodInsuranceInformation')) as any
    return response?.data?.payload
}

const isFloodInsuranceRequired = async (): Promise<boolean> => {
    const response = (await httpClient.get('origination/isFloodInsuranceRequired')) as any
    return response?.data?.payload?.floodInsuranceRequired
}

type RequiredFloodInsuranceAmount = {
    requiredFloodInsuranceAmount: number
    maxDeductible: number
}

const getRequiredFloodInsuranceAmount = async (): Promise<RequiredFloodInsuranceAmount | null> => {
    const response = (await httpClient.get('origination/getRequiredFloodInsuranceAmount')) as BackendResponse<RequiredFloodInsuranceAmount>
    return response?.data?.payload
}

const getIsCondoProperty = async (): Promise<boolean> => {
    const response = (await httpClient.get('origination/isCondoProperty')) as any
    const responsePayload = response?.data?.payload

    return responsePayload?.isCondoProperty
}

const recordFloodDisclosureAcknowledgment = async (payload: DocumentAckMetadataPayload): Promise<any> => {
    return await httpClient.post('origination/recordFloodDisclosureAcknowledgment', payload)
}

// aven_backend/src/manager/disputeProviderDataManager.types.ts
export enum DisputeProviderDataFlowStatus {
    notApplicable = 'notApplicable', // Not applicable for this loan application
    waitingDocuments = 'waitingDocuments', // We are waiting for the applicant to submit documentation for the first time
    documentPendingReview = 'documentPendingReview', // Waiting on ops team to review docs
    documentReviewSuccess = 'documentReviewSuccess', // Docs reviewed with success
    documentReviewFail = 'documentReviewFail', // Docs reviewed with failure
    documentNeverUploaded = 'documentNeverUploaded', // Docs never uploaded
}

const getDisputeProviderDataInformation = async (): Promise<any> => {
    const response = await httpClient.get('origination/getDisputeProviderDataInformation')
    return response?.data?.payload
}

const finishDisputeProviderDataDocSubmission = async (): Promise<any> => {
    const response = await httpClient.post('origination/finishDisputeProviderDataDocSubmission')
    return response
}

const applicantOptOutOfDisputeProviderData = async (): Promise<BackendResponse<{ nextApplicationAction: string }>> => {
    const response = await httpClient.post('origination/applicantOptOutOfDisputeProviderData')
    return response
}

// keep in line with aven_backend/src/manager/incomeVerification.types.ts
const isIncomeVerified = (incomeVerificationResult: IncomeVerificationResultType): boolean => {
    return incomeVerificationResult === IncomeVerificationResultType.incomeVerified
}

const isAssetsVerified = (assetVerificationResult: AssetVerificationResultType): boolean => {
    return assetVerificationResult === AssetVerificationResultType.assetsVerifiedAndSufficient || assetVerificationResult === AssetVerificationResultType.assetVerificationNotApplicable
}

const computeIncomeVerificationResult = async (
    purpose: IncomeVerificationPurpose
): Promise<{ incomeVerificationResult: IncomeVerificationResultType; incomeVerificationMethod: IncomeVerificationMethod }> => {
    const response = await httpClient.get('/computeIncomeVerificationResult', { params: { purpose } })
    return response.data.payload
}

const computeAssetVerificationResult = async (
    purpose: AssetVerificationPurpose
): Promise<BackendResponse<{ assetVerificationResult: AssetVerificationResultType; assetVerificationMethod: AssetVerificationResultType }>> => {
    const response = await httpClient.get('/assetVerification/computeAssetVerificationResult', { params: { purpose } })
    return response
}

const getShouldDisableManualIncomeVerification = async (): Promise<boolean> => {
    const response = await httpClient.get('/getShouldDisableManualIncomeVerification', {})
    return response.data.payload.shouldDisableManualIncomeVerification
}

const saveReview = async (starRating: number, leftTrustPilotReview: boolean, returnToken2: string, feedbackText?: string) => {
    return await httpClient.post('/saveReviewInOrigination', { starRating, leftTrustPilotReview, returnToken2, feedbackText })
}

const createCallBookingLink = async (phoneNumber: string, calendarKey: string | undefined) => {
    return await httpClient.post('/support/createCallBookingLink', { phoneNumber, calendarKey })
}

const createCallBookingForDataSupportLink = async () => {
    return await httpClient.post('/dataSupport/createCallBookingLink')
}

const isMLSContingencyActive = async () => {
    return httpClient.post('/origination/isMLSContingencyActive')
}

const postGoogleTagManagerDataReport = async (eventName: GOOGLE_TAG_MANAGER_EVENT_NAME, requestJson: string) => {
    return await runWithRetryLogic(async () => await httpClient.post('/googleTagManager/createReport', { eventName, requestJson }), 3)
}

const postGoogleTagManagerDataReportForLeadEvent = async (eventName: GOOGLE_TAG_MANAGER_EVENT_NAME, requestJson: string) => {
    return await runWithRetryLogic(async () => await httpClient.post('/googleTagManager/createReportForLead', { eventName, requestJson }), 3)
}

export type GoogleGetLifetimeValuePayload = {
    offerLineSize: number
    offerApr: number
    btcoFeePct: number
}
const getLifetimeValue = async (payload: GoogleGetLifetimeValuePayload): Promise<BackendResponse<number>> => {
    return await httpClient.post('/googleTagManager/getLifetimeValue', payload)
}

const getIsComingFromCompareHelocRates = async (): Promise<BackendResponse<boolean>> => {
    return await httpClient.get('/googleTagManager/getIsComingFromCompareHelocRates')
}

// Keep in sync with aven_backend/src/controller/googleTagManagerController.ts
// and aven_frontend/aven_notary/src/services/api.ts
interface IGetDataToFireGTMEventResponse {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
}

const getDataToFireGTMEvent = async (): Promise<BackendResponse<IGetDataToFireGTMEventResponse>> => {
    return await httpClient.get('/googleTagManager/getDataToFireGTMEvent')
}

const postGetNewSession = async () => {
    return await httpClient.post('/ana/session', getMetadata())
}

/**
 * Function that allows us to activate some referrer-specific experiments to the current session.
 */
export const setReferrerExperimentForSession = async () => {
    const metadata = {
        ...pick(getMetadata(), ['path', 'avenProperty']),
        // Sending current session id prevents the backend from creating a new session, instead it retrieves an existing
        // one: https://github.com/heraclescorp/heracles/blob/develop/aven_backend/src/manager/analyticsManager.ts#L195
        sessionId: appSessionStorage.getItem(sessionStorageKey.sessionId),
    }

    const resp = await httpClient.post('/ana/setReferrerExperimentForSession', metadata)
    persistSessionData(resp.data)
}

export const setInternalUtmSource = async () => {
    const payload = {
        path: window.location.pathname,
        sessionId: appSessionStorage.getItem(sessionStorageKey.sessionId),
    }

    await httpClient.post('/ana/setInternalUtmSource', payload)
}

const submitVisitorId = async (visitorId: string): Promise<BackendResponse<void>> => {
    return await runWithRetryLogic(async () => httpClient.post('/ana/submitVisitorId', { visitorId }), 3)
}

// Subset of aven_backend/src/entity/mail/mail.ts InviteType that origination deals with
export enum InviteType {
    applicant = 'applicant',
    realEstateAgent = 'realEstateAgent',
    pifSender = 'pifSender',
}

// Keep in sync with GetPifCodeInfoResponsePayload in aven_frontend/aven/src/services/api.ts
type GetPifCodeInfoResponsePayload =
    | {
          pifType: InviteType.applicant | InviteType.realEstateAgent
          pifAmount: number
          pifRewardType: string
      }
    | {
          pifType: InviteType.pifSender
          pifAmount: number
          pifSenderName: string
          pifRewardType: string
      }

const getPifInfo = async (pifCode: string): Promise<BackendResponse<GetPifCodeInfoResponsePayload | null>> => {
    return await httpClient.get(`/pif/codeInfo?pifCode=${pifCode}`)
}

export const updateMloApplicationConsent = async (didConsent: boolean, returnToken: string): Promise<AxiosResponse> => {
    const response = await httpClient.get('/updateMloApplicationConsent', {
        params: {
            didConsent,
            returnToken,
        },
    })
    saveApplicantSessionResponse(response)
    setSessionMetadata(getUserTraits())
    return response
}

export const getApplicantsPiiToConfirm = async (returnToken: string): Promise<AxiosResponse> => {
    const response = await httpClient.get('/applicantsPiiToConfirm', {
        params: {
            returnToken,
        },
    })
    saveApplicantSessionResponse(response)
    appSessionStorage.setItem(sessionStorageKey.returnToken2, returnToken)
    setSessionMetadata(getUserTraits())
    return response
}

// Keep in sync with GetPifForAllConfigResponse in aven_backend/src/controller/pifForAllController.ts
interface GetPifForAllConfigResponse {
    currentAmountDollars: number
}

export const getPifForAllConfig = async (): Promise<BackendResponse<GetPifForAllConfigResponse>> => {
    return await httpClient.get('/pifForAll/config')
}

// Keep in sync with CreatePifForAllSenderErrors in aven_backend/src/controller/pifForAllController.ts
export enum CreatePifForAllSenderErrors {
    INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
}

// Keep in sync with PifForAllToken in aven_backend/src/auth/pifForAllAuthService.ts
interface PifForAllToken {
    accessJWT: string
    refreshJWT: string
    accessExpiry: number
}

export const createPifForAllSender = async (phoneNumber: string): Promise<BackendResponse<{ token: PifForAllToken }>> => {
    const response = await httpClient.post<{ token: PifForAllToken }>('/pifForAll/createSender', { phoneNumber })
    if (response.data?.success) {
        appSessionStorage.setItem(sessionStorageKey.jwtTokens, JSON.stringify(response.data.payload.token))
    }
    return response
}

export const completePifForAllSenderProfile = async (firstName: string, lastName: string, email: string, businessName?: string | null): Promise<BackendResponse<{ link: string }>> => {
    return await httpClient.post('/pifForAll/completeSenderProfile', { firstName, lastName, email, businessName })
}

export const sendAdvisorAppLink = async (phoneNumber: string): Promise<BackendResponse<null>> => {
    return await httpClient.post('/sendAdvisorAppLink', { phoneNumber })
}

export const sendAvenMyAppLink = async (phoneNumber: string): Promise<BackendResponse<null>> => {
    return await httpClient.post('/sendAvenMyAppLink', { phoneNumber })
}

export const submitSessionFingerprintAndCallFraudApi = async (encryptedPayload: string) => {
    return await httpClient.post('/seon/submitSessionFingerprintAndCallFraudApi', {
        encryptedPayload,
    })
}

export const submitAccountClosureReason = async (accountClosureReason: AccountClosureReason, otherReason: string | null) => {
    return await httpClient.post('/submitAccountClosureReason', {
        accountClosureReason,
        otherReason: otherReason ? otherReason : undefined,
    })
}

export const submitWithdrawOrDeclineCustomerSurveyResponse = async (withdrawOrDeclineReason: WithdrawOrDeclineReason, otherReason: string | null) => {
    return await httpClient.post('/submitCustomerSurveyResponse', {
        response: {
            reason: withdrawOrDeclineReason,
            otherReason: otherReason ? otherReason : undefined,
        },
        type: CustomerSurveyType.WithdrawOrDecline,
    })
}

const getTrustPilotBusinessData = async (businessUnit: TrustpilotBusinessUnits): Promise<BackendResponse<TrustpilotBusinessUnitsGetResponse>> => {
    return await httpClient.get('/trustpilot/business', {
        params: {
            businessUnit,
        },
    })
}

const getDocusignLegalDownload = async (docType: ValidDocuSignDocType) => {
    return await httpClient.get('/remoteNotarization/docuSign/document', {
        responseType: 'blob',
        params: {
            docType: docType,
        },
    })
}

export const getPhonePrefill = async (): Promise<string | null> => {
    const response = await httpClient.get('/getPhonePrefill')
    if (response.data && response.data.payload?.prefillPhoneNumber) {
        const phone = response.data.payload.prefillPhoneNumber
        appSessionStorage.setItem(sessionStorageKey.phoneNumber, phone)
        logger.info(`Got phone number prefill with data: ${phone}`)
        return phone
    } else {
        logger.info(`Did not receive any phone number prefill with data: ${JSON.stringify(response.data)}`)
        return null
    }
}

const shouldPromptAttestationPageForFirstLien = async () => {
    return await httpClient.post('/shouldPromptFirstLienAttestation')
}

const getOrderedLienDetailsForMortgageAttestation = async () => {
    return await httpClient.post('/getOrderedLienDetailsForMortgageAttestation')
}

export type SubmitLienAttestationInfoPayload =
    | {
          type: LienAttestationType.firstLien | LienAttestationType.helocRefiSecondLien
          statedLienPresence: false
      }
    | {
          type: LienAttestationType.firstLien
          statedLienPresence: true
          statedLoanType: StatedLoanType
          statedLoanOriginationDate: string
          statedLenderName: string
          statedCurrentBalanceAmount: number
          statedMonthlyPaymentAmount: number
      }
    | {
          type: LienAttestationType.helocRefiSecondLien
          statedLienPresence: true
          statedCurrentBalanceAmount: number
          statedMonthlyPaymentAmount: number
      }

export interface SubmitLienAttestationInfoResponse {}

const submitLienAttestationInfo = async (payload: SubmitLienAttestationInfoPayload): Promise<BackendResponse<SubmitLienAttestationInfoResponse>> => {
    return await httpClient.post<SubmitLienAttestationInfoResponse>('/submitLienAttestation', payload)
}

const submitPersonalPropertyAttestationInfo = async (payload: IUpdatePersonalPropertyAttestationInfoPayload): Promise<BackendResponse<SubmitPersonalPropertyAttestationInfoResponse>> => {
    return await httpClient.post<SubmitPersonalPropertyAttestationInfoResponse>('/submitPersonalPropertyAttestationInfo', payload)
}

const submitOrderedLienDetailsAttestationData = async (payload: { statedOrderedLienDetailsJson: string; originalOrderedLienDetailsJson: string }) => {
    return await httpClient.post<SubmitLienAttestationInfoResponse>('/submitOrderedLienDetailsAttestationData', payload)
}

const inviteToApplyOptOut = async (payload: { code: string }) => {
    return await httpClient.post('/dmInviteToApplyOptOut', payload)
}

// please keep in sync with aven_backend/src/entity/lienAttestationInfo.ts
export enum LienAttestationType {
    firstLien = 'firstLien',
    helocRefiSecondLien = 'helocRefiSecondLien',
}

// please keep in sync with aven_backend/src/entity/lienAttestationInfo.ts
export enum StatedLoanType {
    mortgage = 'mortgage',
    heloc = 'heloc',
    heloan = 'heloan',
}

// Microsoft Pixel
// Keep in sync with ...
export enum MICROSOFT_PIXEL_EVENT_NAME {
    LEAD = 'signup',
    VIEW_PREQUAL = 'submit_lead_form',
    ACCEPT_PREQUAL = 'add_to_cart',
    ACCEPT_FINAL_OFFER = 'begin_checkout',
}

// Keep in sync with aven_backend/src/controller/pixelController.ts
interface IGetDataForPixelResponse {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
}

export const getDataForPixel = async (): Promise<BackendResponse<IGetDataForPixelResponse>> => {
    return await httpClient.get('/pixel/getDataForPixel')
}

// Twitter Pixel
export enum TWITTER_PIXEL_EVENT_NAME {
    LEAD = 'tw-o429q-okdgw',
    VIEW_PREQUAL = 'tw-o429q-oke80',
    ACCEPT_PREQUAL = 'tw-o429q-oke81',
    ACCEPT_FINAL_OFFER = 'tw-o429q-oke86',
}

// LinkedIn Pixel
export enum LINKEDIN_PIXEL_EVENT_NAME {
    LEAD = '15744876',
    VIEW_PREQUAL = '15744884',
    ACCEPT_PREQUAL = '15744892',
    ACCEPT_FINAL_OFFER = '15744900',
}

// Reddit Pixel
export enum REDDIT_PIXEL_EVENT_NAME {
    PageVisit = 'PageVisit',
    LEAD = 'Lead',
    VIEW_PREQUAL = 'ViewContent',
    ACCEPT_PREQUAL = 'SignUp',
    ACCEPT_FINAL_OFFER = 'Purchase',
}

export {
    CreateCallBookingLinkError,
    abandonApplication,
    getTrustInfo,
    postTrustInfoForm,
    getPrefilledInformationForMailerLead,
    getPrefilledInformationForMethodFi,
    getPrefilledInformationForMethodFiWithSSNLast4,
    getIsWaitingOnManualDocumentVerification,
    beginPayStubVerification,
    beginTaxReturnVerification,
    beginIrsFormVerification,
    hasStartedIrsFormVerification,
    getApplicantReturning,
    getNextHomeApplicationAction,
    getLegalDocument,
    getLegalDocumentForSession,
    getHtmlLegalDocument,
    LegalDocumentTypes,
    postLegalDocuments,
    plaidReportFetchState,
    startPlaidReportFetch,
    getCanApplicantSubmitPii,
    postUpdateApplicantFields,
    postWaitlistInfo,
    getShouldWaitlistApplicant,
    getDidUnderwritingRelatedInfoChange,
    postUpdateMaritalStatus,
    uploadDocument,
    requestCallMeNow,
    isCallMeBackAvailable,
    sendDataSupportUserFeedback,
    updateApplicantNameFromNonEntityOwnerName,
    createTrustPilotUniqueLink,
    submitPredictionCustomerFeedback,
    getStatePageModules,
    saveStateModuleValues,
    getSavingsForTicker,
    postUpdateAddress,
    getApplicantReturningHMDA,
    getApplicantReturningOGProductExplanation,
    getApplicantReturningForTesting,
    isStateActive,
    isStateSupportedForCurrentLA,
    computeIncomeVerificationResult,
    computeAssetVerificationResult,
    isAssetsVerified,
    getShouldDisableManualIncomeVerification,
    getApplicantReturningToDocumentUploadPortal,
    getFloodInsuranceInformation,
    isFloodInsuranceRequired,
    IncomeVerificationResultType,
    isIncomeVerified,
    getRequiredFloodInsuranceAmount,
    getIsCondoProperty,
    recordFloodDisclosureAcknowledgment,
    saveReview,
    processUpdatedApplicant,
    getDisputeProviderDataInformation,
    finishDisputeProviderDataDocSubmission,
    applicantOptOutOfDisputeProviderData,
    getHtmlLegalDocumentForSession,
    getIsHMDADataCollectedForLoanApplication,
    getUnapprovedLoanApplicationReasons,
    getApplicantState,
    createAvenAdvisorAccountFromOrigination,
    createCallBookingLink,
    createCallBookingForDataSupportLink,
    isMLSContingencyActive,
    postGoogleTagManagerDataReport,
    postGoogleTagManagerDataReportForLeadEvent,
    getLifetimeValue,
    postGetNewSession,
    submitVisitorId,
    getPifInfo,
    getDataToFireGTMEvent,
    getIsComingFromCompareHelocRates,
    getTrustPilotBusinessData,
    getIncomeVerificationAllowedDocs,
    getDocusignLegalDownload,
    initializeIncomeVerificationForNonOwnerOccupied,
    submitLienAttestationInfo,
    submitOrderedLienDetailsAttestationData,
    shouldPromptAttestationPageForFirstLien,
    getOrderedLienDetailsForMortgageAttestation,
    getVerifyCurrentRentDocumentOptions,
    submitPersonalPropertyAttestationInfo,
    inviteToApplyOptOut,
}
