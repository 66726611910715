import { RouteOption, tryGetNextFlowPath, tryGetOptionalPath } from '@/flow/flowUtility'
import { authPagePaths } from '@/routes/authRoutes'
import { originationPagePaths } from '@/routes/originationRoutes'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { logger } from '@/utils/logger'

export const mloApplicantFlow: string[] = [
    authPagePaths.MLO_APPLICATION_PII_CONFIRM,
    originationPagePaths.VERIFY_IDENTITY,
    originationPagePaths.CO_APPLICANT_VERIFY_IDENTITY,
    authPagePaths.RETURN_TO_ANYTHING,
]

const identityQuestionsFlow: string[] = [originationPagePaths.IDENTITY_QUESTIONS]
const ssnVerificationFlow: string[] = [originationPagePaths.SSN_VERIFICATION]
const identityFlowGroup: Map<string, string[]> = new Map([
    [RouteOption.identityQuestions, identityQuestionsFlow],
    [RouteOption.ssnVerification, ssnVerificationFlow],
])

const coApplicantSsnVerificationFlow: string[] = [originationPagePaths.CO_APPLICANT_SSN_VERIFICATION]
const coApplicantIdentityQuestionsFlow: string[] = [originationPagePaths.CO_APPLICANT_IDENTITY_QUESTIONS]
const coApplicantIdentityFlowGroup: Map<string, string[]> = new Map([
    [RouteOption.identityQuestions, coApplicantIdentityQuestionsFlow],
    [RouteOption.coApplicantSsnVerification, coApplicantSsnVerificationFlow],
])

const optionalFlowMap: Map<string, Map<string, string[]>> = new Map([
    [originationPagePaths.VERIFY_IDENTITY, identityFlowGroup],
    [originationPagePaths.CO_APPLICANT_VERIFY_IDENTITY, coApplicantIdentityFlowGroup],
])

export const getMloApplicantNextRouteWithCurrentPath = (currentPath: string, routeOption?: RouteOption): string | null => {
    if (routeOption) {
        return tryGetOptionalPath(currentPath, optionalFlowMap, routeOption)
    }

    const nextRoute = tryGetNextFlowPath(currentPath, mloApplicantFlow, optionalFlowMap)

    if (nextRoute === authPagePaths.RETURN_TO_ANYTHING) {
        const returnToken = appSessionStorage.getItem(sessionStorageKey.returnToken2)
        if (returnToken) {
            return authPagePaths.RETURN_TO_ANYTHING.replace(':returnToken', returnToken)
        }
        logger.error('return token not found')
        return null
    }
    return nextRoute
}
