import Vue from 'vue'
import VueCookies from 'vue-cookies'
import VueMask from 'v-mask'
import VueObserveVisibility from 'vue-observe-visibility'
import { ValidationProvider } from 'vee-validate'
export const initPlugins = (app: typeof Vue) => {
    // we need to register this component globally so that we can use it in aven_shared templates
    app.component('ValidationProvider', ValidationProvider)

    // what is this?
    app.config.productionTip = false

    app.use(VueCookies)
    app.use(VueObserveVisibility)

    app.use(VueMask, {
        // This allows us to use the 'X' character as a regular char
        // https://github.com/probil/v-mask#default-placeholders
        placeholders: {
            X: null,
        },
    })
}
