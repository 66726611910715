import { RouteConfig } from 'vue-router'
import { supportPagePaths } from '@/routes/supportRoutes'

export const marketingPageNames = {
    LANDING: 'landing',
    LANDING_JOIN: 'join',
    PIF_SHARED_LINK_LANDING: 'r',
    PIF_SHARED_LOVE_LANDING: 'love',
    // DO NOT CHANGE from 'linkLanding'. We share PIF links on Facebook using their resolved format:
    // https://aven.com/linkLanding?pifinvitecode=SAM1a2b3c
    // That allows us to provide specific open graph meta data on *only* PIF pages and nothing else.
    // Changing the path will break those links.
    PIF_LANDING: 'linkLanding',
    PIF_TRAVEL_LANDING: 'linkTravelLanding',
    PIF_TRAVEL_BAHAMAS: 'bahamas',

    LANDING_INVITE: 'invite',
    ABOUT: 'about',
    LOCATION_TEST: 'locationTest',
    CAREERS: 'careers',
    PRESS: 'press',
    CONTACT: 'contact',
    PRIVACY: 'privacy',
    DISCLOSURES: 'disclosures',
    SURVEY_THANK_YOU: 'thankyou',
    MOBILE_APPS: 'app',
    // Used for Search App Install Campaign
    APP_INSTALL: 'mobile',
    REVIEWS: 'reviews',
    TESTIMONIALS: 'testimonials',
    ADD_REVIEW: 'addReview',
    PAYMENTS: 'payments',
    PAYMENT_CALCULATOR: 'paymentCalculator',
    FAQ: 'faq',
    LICENSES: 'licenses',
    ADVISOR: 'advisor',
    ADVISOR_APP: 'advisorApp',
    AUTO: 'auto',
    AVEN_MY: 'avenMy',
    HOMEOWNERS_ONLY: 'homeowners',
    LINK_PREVIEW_HELOC_CREDIT_CARD: 'helocCreditCard',
    LINK_PREVIEW_COMPARE_HELOC: 'compareHeloc',
    LINK_PREVIEW_CLOSE_FAST: 'closeFast',
    LINK_PREVIEW_NOTARY_ONLINE: 'notaryOnline',
    LANDING_UCC: 'ucc',
    INVITE_TO_APPLY_OPT_OUT: `inviteToApplyOptOut`,
    OPT_OUT: `optOut`,
}

export const marketingPagePaths = {
    LANDING: `/`,
    LANDING_JOIN: `/${marketingPageNames.LANDING_JOIN}`,
    PIF_SHARED_LINK_LANDING: `/${marketingPageNames.PIF_LANDING}/:p`,
    PIF_SHARED_LOVE_LANDING: `/${marketingPageNames.PIF_SHARED_LOVE_LANDING}/:p`,
    // DO NOT CHANGE the PIF_LANDING path. We share PIF links on Facebook using their resolved format:
    // https://aven.com/linkLanding?pifinvitecode=SAM1a2b3c
    // That allows us to provide specific open graph meta data on *only* PIF pages and nothing else.
    // Changing the path will break those links.
    PIF_LANDING: `/${marketingPageNames.PIF_LANDING}`,
    PIF_TRAVEL_LANDING: `/${marketingPageNames.PIF_TRAVEL_LANDING}`,
    PIF_TRAVEL_BAHAMAS: `/${marketingPageNames.PIF_TRAVEL_BAHAMAS}`,

    LANDING_INVITE: `/${marketingPageNames.LANDING_INVITE}`,
    ABOUT: `/${marketingPageNames.ABOUT}`,
    CAREERS: `/${marketingPageNames.CAREERS}`,
    PRESS: `/${marketingPageNames.PRESS}`,
    PRIVACY: `/${marketingPageNames.PRIVACY}`,
    DISCLOSURES: `/${marketingPageNames.DISCLOSURES}`,
    SURVEY_THANK_YOU: `/survey/${marketingPageNames.SURVEY_THANK_YOU}`,
    MOBILE_APPS: `/${marketingPageNames.MOBILE_APPS}`,
    APP_INSTALL: `/${marketingPageNames.APP_INSTALL}`,
    LICENSES: `/${marketingPageNames.LICENSES}`,
    CONTACT: `/${marketingPageNames.CONTACT}`,
    REVIEWS: `/${marketingPageNames.REVIEWS}`,
    TESTIMONIALS: `/${marketingPageNames.TESTIMONIALS}`,
    ADD_REVIEW: `/${marketingPageNames.ADD_REVIEW}`,
    ADD_REVIEW_PARAMETERIZED: `/${marketingPageNames.ADD_REVIEW}/:p`,
    PAYMENTS: `/${marketingPageNames.PAYMENTS}`,
    PAYMENT_CALCULATOR: `/${marketingPageNames.PAYMENT_CALCULATOR}`,
    FAQ: `/${marketingPageNames.FAQ}`,
    ADVISOR: `/${marketingPageNames.ADVISOR}`,
    ADVISOR_APP: `/${marketingPageNames.ADVISOR_APP}`,
    AUTO: `/${marketingPageNames.AUTO}`,
    AVEN_MY: `/${marketingPageNames.AVEN_MY}`,
    LOCATION_TEST: `/${marketingPageNames.LOCATION_TEST}`,
    HOMEOWNERS_ONLY: `/${marketingPageNames.HOMEOWNERS_ONLY}`,
    LINK_PREVIEW_HELOC_CREDIT_CARD: `/${marketingPageNames.LINK_PREVIEW_HELOC_CREDIT_CARD}`,
    LINK_PREVIEW_COMPARE_HELOC: `/${marketingPageNames.LINK_PREVIEW_COMPARE_HELOC}`,
    LINK_PREVIEW_CLOSE_FAST: `/${marketingPageNames.LINK_PREVIEW_CLOSE_FAST}`,
    LINK_PREVIEW_NOTARY_ONLINE: `/${marketingPageNames.LINK_PREVIEW_NOTARY_ONLINE}`,
    LANDING_UCC: `/${marketingPageNames.LANDING_UCC}`,
    INVITE_TO_APPLY_OPT_OUT: `/${marketingPageNames.INVITE_TO_APPLY_OPT_OUT}`,
    OPT_OUT: `/${marketingPageNames.OPT_OUT}`,
}

const infoPageRoutes: RouteConfig[] = [
    {
        path: marketingPagePaths.ABOUT,
        name: marketingPageNames.ABOUT,
        component: () => import(/* webpackChunkName: "about" */ '@/pages/marketing/AboutPage.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.LOCATION_TEST,
        name: marketingPageNames.LOCATION_TEST,
        component: () => import(/* webpackChunkName: "about" */ '@/pages/marketing/LocationTest.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.REVIEWS,
        name: marketingPageNames.REVIEWS,
        component: () => import(/* webpackChunkName: "reviews" */ '@/pages/marketing/ReviewsPage.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.TESTIMONIALS,
        name: marketingPageNames.TESTIMONIALS,
        component: () => import(/* webpackChunkName: "testimonials" */ '@/pages/marketing/TestimonialsPage.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.CAREERS,
        name: marketingPageNames.CAREERS,
        component: () => import(/* webpackChunkName: "careers" */ '@/pages/marketing/CareersPage.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.PRESS,
        name: marketingPageNames.PRESS,
        component: () => import(/* webpackChunkName: "press" */ '@/pages/marketing/PressPage.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.PRIVACY,
        name: marketingPageNames.PRIVACY,
        component: () => import(/* webpackChunkName: "privacy" */ '@/pages/marketing/PrivacyPage.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.DISCLOSURES,
        name: marketingPageNames.DISCLOSURES,
        component: () => import(/* webpackChunkName: "disclosures" */ '@/pages/marketing/ImportantDisclosures.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.CONTACT,
        name: marketingPageNames.CONTACT,
        component: () => import(/* webpackChunkName: "contact" */ '@/pages/marketing/ContactPage.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.PAYMENTS,
        redirect: () => {
            return { path: marketingPagePaths.PAYMENT_CALCULATOR }
        },
    },
    {
        path: marketingPagePaths.PAYMENT_CALCULATOR,
        name: marketingPageNames.PAYMENT_CALCULATOR,
        component: () => import(/* webpackChunkName: "paymentCalculator" */ '@/pages/marketing/PaymentCalculatorPage.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.MOBILE_APPS,
        name: marketingPageNames.MOBILE_APPS,
        component: () => import(/* webpackChunkName: "mobileApps" */ '@/pages/marketing/MobileApps.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.APP_INSTALL,
        name: marketingPageNames.APP_INSTALL,
        component: () => import(/* webpackChunkName: "mobileApps" */ '@/pages/marketing/AppInstall.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.LICENSES,
        name: marketingPageNames.LICENSES,
        component: () => import(/* webpackChunkName: "mobileApps" */ '@/pages/marketing/LicensesPage.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.SURVEY_THANK_YOU,
        name: marketingPageNames.SURVEY_THANK_YOU,
        component: () => import(/* webpackChunkName: "support" */ '@/pages/survey/ThankYou.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.ADD_REVIEW,
        name: marketingPageNames.ADD_REVIEW,
        component: () => import(/* webpackChunkName: "reviewsGathering" */ '@/pages/marketing/AddReview.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.ADD_REVIEW_PARAMETERIZED,
        redirect: (to) => {
            return { path: marketingPageNames.ADD_REVIEW, query: { p: to.params.p } }
        },
    },
    {
        path: marketingPagePaths.FAQ,
        name: marketingPageNames.FAQ,
        redirect: (to) => {
            return { path: supportPagePaths.SUPPORT_HOME, query: { p: to.params.p } }
        },
    },
    {
        path: marketingPagePaths.ADVISOR,
        component: () => import(/* webpackChunkName: "advisor" */ '@/pages/marketing/AdvisorPage.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.ADVISOR_APP,
        beforeEnter() {
            // redirect to app store
            // this link redirects to android/ios app store based on device
            location.href = 'https://avenadvisorapp.page.link/app'
        },
        meta: { public: true },
    },
    {
        path: marketingPagePaths.AUTO,
        beforeEnter() {
            if (process.env.VUE_APP_AUTO_URL) {
                location.href = process.env.VUE_APP_AUTO_URL
            }
        },
        meta: { public: true },
    },
    {
        path: marketingPagePaths.AVEN_MY,
        beforeEnter() {
            // Custom product page for Aven My HELOC Origination
            location.href = 'https://apps.apple.com/us/app/aven-card/id1541705487?ppid=b6c7770b-0a59-49dd-8b44-52e1862b8d8b'
        },
        meta: { public: true },
    },
    {
        path: marketingPagePaths.HOMEOWNERS_ONLY,
        name: marketingPageNames.HOMEOWNERS_ONLY,
        component: () => import(/* webpackChunkName: "reviewsGathering" */ '@/pages/marketing/HomeownersOnly.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.INVITE_TO_APPLY_OPT_OUT,
        name: marketingPageNames.INVITE_TO_APPLY_OPT_OUT,
        component: () => import(/* webpackChunkName: "inviteToApplyOptOut" */ '@/pages/marketing/InviteToApplyOptOut.vue'),
        meta: { public: true },
    },
    {
        path: marketingPagePaths.OPT_OUT,
        name: marketingPageNames.OPT_OUT,
        component: () => import(/* webpackChunkName: "optOut" */ '@/pages/marketing/InviteToApplyOptOut.vue'),
        meta: { public: true },
    },
]

export const marketingPageRoutes: RouteConfig[] = [...infoPageRoutes]
