"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FicoScoreBand = exports.ApprovalChance = void 0;
var ApprovalChance;
(function (ApprovalChance) {
    ApprovalChance["Excellent"] = "excellent";
    ApprovalChance["VeryGood"] = "very_good";
    ApprovalChance["Good"] = "good";
    ApprovalChance["Fair"] = "fair";
    ApprovalChance["Poor"] = "poor";
})(ApprovalChance = exports.ApprovalChance || (exports.ApprovalChance = {}));
var FicoScoreBand;
(function (FicoScoreBand) {
    FicoScoreBand["Exceptional"] = "exceptional";
    FicoScoreBand["VeryGood"] = "very_good";
    FicoScoreBand["Good"] = "good";
    FicoScoreBand["Fair"] = "fair";
    FicoScoreBand["Poor"] = "poor";
})(FicoScoreBand = exports.FicoScoreBand || (exports.FicoScoreBand = {}));
