import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { appLocalStorage, appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { inspect, logger } from '@/utils/logger'
import { isSafariPrivateBrowsing } from '@/utils/parseUserAgents'
import router from '@/routes/router'
import { sharedPagePaths } from '@/routes/sharedRoutes'
import { getUnderwritingMetadata, isUnderwritingMetadataReady } from './uwMetaData'
import OpenReplayTracker from '@openreplay/tracker'
import OpenReplayAxiosTracker from '@openreplay/tracker-axios'
import { ThanksPageReasons } from '@/utils/thanksPageHelpers'
import { mloUtils } from '@/utils/mloUtils'

export type BackendResponse<T, EPT = null> = AxiosResponse<{ success: true; payload: T; error: null } | { success: false; payload: T | EPT; error: string }>

export const isProdEnv = process.env.VUE_APP_NODE_ENV === 'production'
const timeout = isProdEnv ? 40000 : Number(appLocalStorage.getItem(sessionStorageKey.httpTimeout) ?? 20000) // Some browsers have 10 sec timeouts, simulate this in the dev env with potential override
console.log(`HTTP timeout set to ${timeout}`)

const config = {
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
    timeout,
}

const axiosInstance = axios.create(config)
const coApplicantAxiosInstance = axios.create(config)
const mloAxiosInstance = axios.create(config)
const mloLoanAxiosInstance = axios.create(config)
const loggerAxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_LOGGER_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
    },
    responseType: 'text',
    timeout,
})

const authInterceptor = (request: any) => {
    /** add auth token */
    let accessToken
    const jwtTokenJson = appSessionStorage.getItem(sessionStorageKey.jwtTokens)
    try {
        if (jwtTokenJson) {
            accessToken = JSON.parse(jwtTokenJson).accessJWT
        }
    } catch (error) {
        logger.error(`authInterceptor request: ${request.url} jwt json: ${jwtTokenJson}`, error)
    }

    if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`
    }
    // Custom header for SessionJWT Authentication strategy
    const sessionAccessToken = appSessionStorage.getItem(sessionStorageKey.sessionAccessJWT)
    if (sessionAccessToken) {
        request.headers.SessionAuthorization = `Bearer ${sessionAccessToken}`
    }

    return request
}

const coApplicantAuthInterceptor = (request: any) => {
    /** add auth token */
    let accessToken
    const jwtTokenJson = appSessionStorage.getItem(sessionStorageKey.coApplicantJwtTokens)
    try {
        if (jwtTokenJson) {
            accessToken = JSON.parse(jwtTokenJson).accessJWT
        }
    } catch (error: any) {
        logger.error(`co applicant authInterceptor request: ${request.url} jwt json: ${jwtTokenJson}`, null /* event */, error)
    }

    if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`
    }
    // Custom header for SessionJWT Authentication strategy
    const sessionAccessToken = appSessionStorage.getItem(sessionStorageKey.sessionAccessJWT)
    if (sessionAccessToken) {
        request.headers.SessionAuthorization = `Bearer ${sessionAccessToken}`
    }

    return request
}

const mloAuthInterceptor = async (request: AxiosRequestConfig) => {
    let accessToken: string | undefined
    try {
        accessToken = appLocalStorage.getItem(sessionStorageKey.mloJwtTokens)
    } catch (error: any) {
        logger.error(`mlo authInterceptor request: ${request.url}`, null /* event */, error)
    }

    if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`
    }
    // Custom header for SessionJWT Authentication strategy
    const sessionAccessToken = appSessionStorage.getItem(sessionStorageKey.sessionAccessJWT)
    if (sessionAccessToken) {
        request.headers.SessionAuthorization = `Bearer ${sessionAccessToken}`
    }

    return request
}

const mloLoanAuthInterceptor = async (request: CustomAxiosRequestConfig) => {
    let accessToken: string | undefined
    try {
        if (request.extraData?.['loanApplicationId']) {
            // If we have a loanApplicationId, we need to add the MLO JWT for that loan application
            const mloJwt = await mloUtils.getOrCreateMLOJwtForLoanApplication(request.extraData['loanApplicationId'])
            accessToken = mloJwt.jwt
        }
    } catch (error: any) {
        logger.error(`mlo authInterceptor request: ${request.url}`, null /* event */, error)
    }

    if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`
    }
    // Custom header for SessionJWT Authentication strategy
    const sessionAccessToken = appSessionStorage.getItem(sessionStorageKey.sessionAccessJWT)
    if (sessionAccessToken) {
        request.headers.SessionAuthorization = `Bearer ${sessionAccessToken}`
    }

    return request
}

/** Adding the request interceptors */
axiosInstance.interceptors.request.use(authInterceptor)
coApplicantAxiosInstance.interceptors.request.use(coApplicantAuthInterceptor)
mloAxiosInstance.interceptors.request.use(mloAuthInterceptor)
// It will have a different MLO JWT for the current loan application
mloLoanAxiosInstance.interceptors.request.use(mloLoanAuthInterceptor)

/** Adding the response interceptors */
// Response interceptor for API calls
mloLoanAxiosInstance.interceptors.response.use(
    (response) => {
        return response
    },
    async function (error) {
        const originalRequest = error.config
        if (error.response.status in [401, 403] && !originalRequest._retry) {
            originalRequest._retry = true
            // Loan session tokens are stale, clear them, so we get new ones on the next request
            mloUtils.clearMloLoanJwt()
            return mloLoanAxiosInstance(originalRequest)
        }
        return Promise.reject(error)
    }
)

const windowSizeInMsec = 1000 * 60
let windowStartTime = new Date()
let callDurationsDuringWindow: number[] = []
let erroredCallsDuringWindow = 0
let totalCallsDuringWindow = 0

const printAndResetNetworkStats = () => {
    if (totalCallsDuringWindow > 0) {
        if (new Date().getTime() - windowStartTime.getTime() > windowSizeInMsec) {
            const minCallDuration = Math.min(...callDurationsDuringWindow)
            const maxCallDuration = Math.max(...callDurationsDuringWindow)
            const averageCallDuration = Math.round(callDurationsDuringWindow.reduce((a, b) => a + b, 0) / callDurationsDuringWindow.length)
            const stdDeviation = Math.round(getStandardDeviation(callDurationsDuringWindow))

            logger.info(
                `Network stats over last ${windowSizeInMsec} msec: / Attempted network calls: ${totalCallsDuringWindow} / Errored network calls: ${erroredCallsDuringWindow}. Min / max / avg / std-dev call duration: ${minCallDuration} msec / ${maxCallDuration} msec / ${averageCallDuration} msec / ${stdDeviation} msec`
            )

            // Reset window parameter to initial values
            totalCallsDuringWindow = 0
            erroredCallsDuringWindow = 0
            callDurationsDuringWindow = []
            windowStartTime = new Date()
        }
    }
}
setInterval(printAndResetNetworkStats, windowSizeInMsec)

// https://stackoverflow.com/a/53577159/858775
function getStandardDeviation(array: number[]) {
    const n = array?.length || 0
    if (n === 0 || n === 1) {
        return 0
    }

    const mean = array.reduce((a, b) => a + b) / n
    return Math.sqrt(array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
}

const getCustomConfigWithCustomHeaders = (customHeaders: { [key: string]: string }, originalConfig?: any) => {
    const customConfig = { ...(originalConfig ?? {}) }
    customConfig.headers = { ...(customConfig.headers ?? {}), ...customHeaders }
    return customConfig
}

export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
    extraData?: any
}
class HttpClient {
    private readonly axiosInstance: AxiosInstance

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance
    }

    private handleError = async (e: any) => {
        // HTTP 423 === 'Locked', we use this as an expected status for waiting on things to complete
        // Don't count that against out errored calls count
        if (e?.response?.status !== 423) {
            erroredCallsDuringWindow++
        }
        if (!appSessionStorage.getItem(sessionStorageKey.sessionAccessJWT) && isSafariPrivateBrowsing()) {
            await router.push({ path: sharedPagePaths.THANKS, query: { reason: ThanksPageReasons.privateBrowsing } })
            return
        }
        throw e
    }

    get = async <RT = any>(path: string, config?: CustomAxiosRequestConfig): Promise<BackendResponse<RT>> => {
        try {
            let customConfig = config
            if (!isProdEnv) {
                const abTestOverrides = appSessionStorage.getItem(sessionStorageKey.abTestOverrides)
                customConfig = getCustomConfigWithCustomHeaders({ 'X-AB-Test-Overrides': abTestOverrides || '' }, config)
            }

            const startTime = new Date()
            totalCallsDuringWindow++
            const res = await this.axiosInstance.get<any, BackendResponse<RT>>(path, customConfig)

            const durationMsec = new Date().getTime() - startTime.getTime()
            callDurationsDuringWindow.push(durationMsec)

            return res
        } catch (e) {
            await this.handleError(e)
            return Promise.reject()
        }
    }

    post = async <RT = any, PT = any>(path: string, data?: PT, config?: CustomAxiosRequestConfig): Promise<BackendResponse<RT>> => {
        try {
            let customConfig = config || {}
            if (!isProdEnv) {
                const abTestOverrides = appSessionStorage.getItem(sessionStorageKey.abTestOverrides)
                customConfig = getCustomConfigWithCustomHeaders({ 'X-AB-Test-Overrides': abTestOverrides || '' }, config)
            }

            const startTime = new Date()
            totalCallsDuringWindow++
            const res = await this.axiosInstance.post<any, BackendResponse<RT>>(path, data, customConfig)

            const durationMsec = new Date().getTime() - startTime.getTime()
            callDurationsDuringWindow.push(durationMsec)

            return res
        } catch (e) {
            await this.handleError(e)
            return Promise.reject()
        }
    }

    initializeOpenReplayRequestTracker = (openReplayTracker: OpenReplayTracker) => {
        try {
            logger.info(`Adding request watcher for openreplay`)
            openReplayTracker.use(
                OpenReplayAxiosTracker({
                    instance: this.axiosInstance,
                    ignoreHeaders: ['sessionauthorization', 'authorization'],
                    sanitiser: (reqRespData) => {
                        const resBody = reqRespData.response.body
                        if (typeof resBody === 'string') {
                            reqRespData.response.body = { msg: resBody }
                        }
                        return reqRespData
                    },
                })
            )
        } catch (error) {
            logger.error(`Could not initialize openReplay axios tracker`, error)
        }
    }
}

class MloLoanHttpClient extends HttpClient {
    private getCustomConfigForLoanApplication = (loanApplicationId: number, configParam?: CustomAxiosRequestConfig): CustomAxiosRequestConfig => {
        const config = configParam || {}
        const configExtraData = config.extraData || {}
        return { ...config, extraData: { ...configExtraData, loanApplicationId } }
    }
    async getForLoanApplication(loanApplicationId: number, path: string, configParam?: CustomAxiosRequestConfig): Promise<AxiosResponse> {
        const axiosConfig = this.getCustomConfigForLoanApplication(loanApplicationId, configParam)
        return this.get(path, this.getCustomConfigForLoanApplication(loanApplicationId, axiosConfig))
    }

    async postForLoanApplication(loanApplicationId: number, path: string, data?: any, configParam?: AxiosRequestConfig): Promise<AxiosResponse> {
        const axiosConfig = this.getCustomConfigForLoanApplication(loanApplicationId, configParam)
        return this.post(path, data, axiosConfig)
    }
}

const httpClient = new HttpClient(axiosInstance)
const coApplicantHttpClient = new HttpClient(coApplicantAxiosInstance)
const loggerHttpClient = new HttpClient(loggerAxiosInstance)
const mloHttpClient = new HttpClient(mloAxiosInstance)

const mloLoanHttpClient = new MloLoanHttpClient(mloLoanAxiosInstance)

const RETRY_INTERVAL_MSEC = 6000

const runWithRetryLogic = async <T>(requestFunc: () => Promise<T>, maxRetryCount: number, customRetryInterval?: number, errHandler?: (exception: any) => Promise<void>): Promise<T> =>
    new Promise((resolve, reject) => {
        const retryInterval = customRetryInterval || RETRY_INTERVAL_MSEC

        let attemptNumber = 0
        const retryAndLog = async () => {
            try {
                const ret = await requestFunc()
                return resolve(ret)
            } catch (error: any) {
                const allowRetry = error.response?.status === undefined || error.response?.status === 0 || error.code === 'ECONNABORTED' || error.code === 'ECONNRESET'
                if (!allowRetry) {
                    return reject(error)
                }

                logger.info(`Retry attempt ${attemptNumber}/${maxRetryCount} failed due to error: ${inspect(error)}`)
                attemptNumber++
                if (attemptNumber > maxRetryCount) {
                    return reject(new Error(`Max retry limit of ${maxRetryCount} exceeded with error: ${inspect(error)}`))
                }
                logger.info(`Next retry attempt in ${retryInterval} ms`)

                try {
                    if (errHandler) {
                        logger.info(`Calling error handler`)
                        await errHandler(error)
                    }
                } catch (e) {
                    logger.fatal(`Error handler failed`, e)
                }

                setTimeout(retryAndLog, retryInterval)
            }
        }
        retryAndLog()
    })

export { httpClient, coApplicantHttpClient, loggerHttpClient, mloHttpClient, mloLoanHttpClient, runWithRetryLogic }

// will check if the user has been firing the still_here event idly for 10 seconds * 30 times = 5 minutes
const INACTIVE_MAX_COUNT = 30
let stillHereCounter = 0
const isStillHere = (eventName: string) => {
    if (eventName !== 'still_here') {
        // new event was received, reset counter
        stillHereCounter = 0
    } else if (stillHereCounter <= INACTIVE_MAX_COUNT) {
        stillHereCounter += 1
    }
    // return true if user has not been idle for over 5 minutes
    return stillHereCounter <= INACTIVE_MAX_COUNT
}

const logEventFactory = (sessionIdGetter: (loanId?: number) => Promise<string>) => {
    return async function (eventName: string, properties?: object | { key: string; value: string }): Promise<void> {
        // Don't try sending events to the backend in prerender mode to avoid errors
        if (window.prerender) {
            return
        }

        if (!isStillHere(eventName)) {
            // user has been idle for over 5 minutes, will not send events until new event is received
            return
        }

        const sessionId = await sessionIdGetter()
        if (eventName === 'still_here' && !sessionId) {
            // Ignore still_here events if we are missing a session_id
            // They're for tracking session duration and obviously no sessionId == no session duration
            return
        }

        const experimentName = isUnderwritingMetadataReady() ? getUnderwritingMetadata()?.ExperimentName : 'prerender'

        const body = {
            eventName,
            properties: {
                ...properties,
                currentPath: window.location.pathname,
                previousPath: window.previousPath,
                experimentName,
                // eslint-disable-next-line no-storage/no-browser-storage
                sessionStorage: appSessionStorage.getAll(),
            },
            sessionId,
        }

        await runWithRetryLogic(async () => await httpClient.post('/ana/evnt', body), 2).catch((e) => {
            logger.fatal(`analytics event failed! Note that this does not impact the users experience`, e)
        })
    }
}

export const logEvent = logEventFactory(async () => {
    const sessionIdPromise: Promise<string> = Promise.resolve().then(async (): Promise<string> => {
        // this is fine bc we are doing a promise.race below
        // eslint-disable-next-line no-constant-condition
        while (true) {
            // Poll for session id
            const sessionId = appSessionStorage.getItem(sessionStorageKey.sessionId)
            if (sessionId) {
                return sessionId
            }
            await new Promise((resolve) => setTimeout(resolve, 200))
        }
    })

    const sessionIdTimeoutPromise = new Promise<string>((_, reject) => setTimeout(() => reject(new Error('Session id not found in 5 seconds')), 5000))

    return Promise.race([sessionIdPromise, sessionIdTimeoutPromise])
})
window.logEvent = logEvent
window.logMloLoanEvent = async (loanId: number, eventName: string, properties?: object | { key: string; value: string }) => {
    const logEventFunction = logEventFactory(async () => {
        const mloJwt = await mloUtils.getOrCreateMLOJwtForLoanApplication(loanId)
        return mloJwt.sessionId
    })

    await logEventFunction(eventName, properties)
}
