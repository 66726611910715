"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoogleConversionNameToActionId = exports.GoogleConversionAdjustmentType = exports.GoogleConversionName = void 0;
var GoogleConversionName;
(function (GoogleConversionName) {
    GoogleConversionName["lead"] = "lead";
    GoogleConversionName["prequalified"] = "prequalified";
    GoogleConversionName["acceptPrequal"] = "acceptPrequal";
    GoogleConversionName["viewOffer"] = "viewOffer";
    GoogleConversionName["acceptOffer"] = "acceptOffer";
    GoogleConversionName["schedule"] = "schedule";
    GoogleConversionName["primaryCreditCardCreated"] = "primaryCreditCardCreated";
    GoogleConversionName["avenCashPQ"] = "avenCashPQ";
    // advisor events
    GoogleConversionName["advisorHomeownerLead"] = "advisorHomeownerLead";
    GoogleConversionName["advisorPhoneNumberSubmit"] = "advisorPhoneNumberSubmit";
    GoogleConversionName["advisorOTPSuccess"] = "advisorOTPSuccess";
    GoogleConversionName["advisorSSNSuccess"] = "advisorSSNSuccess";
    GoogleConversionName["advisorPIISuccess"] = "advisorPIISuccess";
    GoogleConversionName["advisorKBASuccess"] = "advisorKBASuccess";
    GoogleConversionName["advisorNotificationPermissionGranted"] = "advisorNotificationPermissionGranted";
    GoogleConversionName["advisorStatedGoalSubmit"] = "advisorStatedGoalSubmit";
    GoogleConversionName["advisorStatedGoalLienReports"] = "advisorStatedGoalLienReports";
    GoogleConversionName["advisorStatedGoalBudgetSpendTracking"] = "advisorStatedGoalBudgetSpendTracking";
    GoogleConversionName["advisorStatedGoalFindSubscriptions"] = "advisorStatedGoalFindSubscriptions";
    GoogleConversionName["advisorStatedGoalCreditScore"] = "advisorStatedGoalCreditScore";
    GoogleConversionName["advisorStatedGoalOther"] = "advisorStatedGoalOther";
})(GoogleConversionName = exports.GoogleConversionName || (exports.GoogleConversionName = {}));
var GoogleConversionAdjustmentType;
(function (GoogleConversionAdjustmentType) {
    // To adjusting the value of a conversion
    GoogleConversionAdjustmentType["RESTATEMENT"] = "RESTATEMENT";
    // There are more but we're not using them so far: https://developers.google.com/google-ads/api/rest/reference/rest/v16/customers/uploadConversionAdjustments#ConversionAdjustmentType
})(GoogleConversionAdjustmentType = exports.GoogleConversionAdjustmentType || (exports.GoogleConversionAdjustmentType = {}));
exports.GoogleConversionNameToActionId = {
    [GoogleConversionName.lead]: '6822101196',
    [GoogleConversionName.prequalified]: '6822101199',
    [GoogleConversionName.acceptPrequal]: '6822101202',
    [GoogleConversionName.viewOffer]: '6822101205',
    [GoogleConversionName.acceptOffer]: '6827432110',
    [GoogleConversionName.schedule]: '6827432113',
    [GoogleConversionName.primaryCreditCardCreated]: '6827432116',
    [GoogleConversionName.avenCashPQ]: '6830012285',
    [GoogleConversionName.advisorHomeownerLead]: '6864502446',
    [GoogleConversionName.advisorPhoneNumberSubmit]: '6882140060',
    [GoogleConversionName.advisorOTPSuccess]: '6882140063',
    [GoogleConversionName.advisorSSNSuccess]: '6882140066',
    [GoogleConversionName.advisorPIISuccess]: '6882140069',
    [GoogleConversionName.advisorKBASuccess]: '6882140072',
    [GoogleConversionName.advisorNotificationPermissionGranted]: '6882140075',
    [GoogleConversionName.advisorStatedGoalSubmit]: '6882140078',
    [GoogleConversionName.advisorStatedGoalLienReports]: '6882140081',
    [GoogleConversionName.advisorStatedGoalBudgetSpendTracking]: '6882140087',
    [GoogleConversionName.advisorStatedGoalFindSubscriptions]: '6882140084',
    [GoogleConversionName.advisorStatedGoalCreditScore]: '6882140090',
    [GoogleConversionName.advisorStatedGoalOther]: '6882140093',
};
