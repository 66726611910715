"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvenPaymentAllocationPattern = void 0;
var AvenPaymentAllocationPattern;
(function (AvenPaymentAllocationPattern) {
    AvenPaymentAllocationPattern["coreCardDefault"] = "coreCardDefault";
    AvenPaymentAllocationPattern["avenGracePeriodSupport"] = "avenGracePeriodSupport";
    AvenPaymentAllocationPattern["payDownAllPlans"] = "payDownAllPlans";
    AvenPaymentAllocationPattern["TESTING_ONLY_payAllPlansEqually"] = "TESTING_ONLY_payAllPlansEqually";
})(AvenPaymentAllocationPattern = exports.AvenPaymentAllocationPattern || (exports.AvenPaymentAllocationPattern = {}));
